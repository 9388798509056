import { MutableRefObject, useCallback, useEffect, useRef } from "react"
import { useTracking } from "../../../hooks/tracking"
import { ClientTrackingEventName } from "../../../types"
import { IUseWizardTracking } from "../types"

const getActiveCustomerStepIdx = (ref: MutableRefObject<null>) => {
  return (ref.current as any)?.indexCurrent || 0
}

export const useWizardTracking = (
  title: string,
  screensMap: string[],
  activeStepIdx: number,
  isExpertFound: boolean
): IUseWizardTracking => {
  const wizardUniqueId = useRef(`wizard-${new Date().getTime()}`)

  const { trackEvent } = useTracking()

  const customerStepsRef = useRef(null)

  const getStepNameFromMap = useCallback(
    (offset = 0) => {
      return screensMap[
        activeStepIdx + getActiveCustomerStepIdx(customerStepsRef) - offset
      ]
    },
    [activeStepIdx, screensMap]
  )

  const trackWizardStep = useCallback<IUseWizardTracking["trackWizardStep"]>(
    ({ eventName, offset = 0, choice, step }) => {
      trackEvent({
        eventName,
        properties: {
          wizard_id: wizardUniqueId.current,
          wizard_name: title,
          step: step || getStepNameFromMap(offset),
          ...(choice && { choice }),
        },
      })
    },
    [getStepNameFromMap, title, trackEvent]
  )

  useEffect(() => {
    trackWizardStep({ eventName: ClientTrackingEventName.WIZARD_STEP_VIEWED })
  }, [activeStepIdx])

  useEffect(() => {
    if (isExpertFound) {
      trackWizardStep({
        eventName: ClientTrackingEventName.WIZARD_STEP_COMPLETED,
        step: "auth",
      })

      trackWizardStep({
        eventName: ClientTrackingEventName.WIZARD_STEP_VIEWED,
        step: "expert",
      })
    }
  }, [isExpertFound])

  return {
    trackWizardStep,
    customerStepsRef,
  }
}
