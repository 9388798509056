import React, { useCallback, useState } from "react"
import {
  FiltersModuleFieldId,
  IExpertsFilter,
  IFilterOption,
} from "../../../types"
import { WizardButtonGroup } from "../WizardButtonGroup"
import { WizardStep } from "./Step"
import classes from "./Mood.module.scss"

interface IMoodStep {
  title: string
  type: FiltersModuleFieldId
  goToNextStep: (option?: string) => void
}

export const Mood: React.FC<IMoodStep> = ({ title, type, goToNextStep }) => {
  const [filter, setFilter] = useState<IExpertsFilter>({
    id: type,
    enabled: true,
    title: title as string,
    options: [
      {
        name: "Super!",
        id: "mood1",
        enabled: true,
      },
      {
        name: "So la la",
        id: "mood2",
        enabled: true,
      },
      {
        name: "Nicht gut",
        id: "mood3",
        enabled: true,
      },
    ],
    defaultOption: "",
  })

  const handleFilterChange = useCallback(
    (activeOption: IFilterOption["id"]) => {
      setFilter({
        ...filter,
        defaultOption: activeOption,
      })

      goToNextStep(activeOption.toString())
    },
    [filter, goToNextStep]
  )

  return (
    <WizardStep title={title} rootClassName={classes.root}>
      <WizardButtonGroup
        filter={filter!}
        handleFilterChange={handleFilterChange}
      />
    </WizardStep>
  )
}
