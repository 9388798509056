import React from "react"
import { IIconProps } from "../../_common/QuesticoIcon/types"

const WaveBlueBg: React.FC<IIconProps> = ({
  height = "100%",
  width = "100%",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 8566 476"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M211.888 175.176C31.4109 163.143 -4.30854 260.409 0.391385 310.547V475.999H8566V39.806C8530.75 -20.3586 8361.55 -5.31748 8206.46 39.806C8051.36 84.9295 7980.86 220.3 7839.86 257.903C7698.86 295.505 7571.97 220.3 7445.07 175.176C7318.17 130.053 7282.92 272.944 7120.77 257.903C7018.16 248.384 7043.23 175.176 6866.98 99.9706C6725.98 39.8059 6582.63 74.902 6528.58 99.9706C6469.83 140.08 6374.05 130.763 6246.59 117.471C6127 105 6155.5 90.4996 6032 77.9996C5928.09 67.4827 5843 157 5783 204C5723 251 5664.6 309.798 5545.5 220.3C5424 129 5382 67.9996 5303 105C5213.05 147.129 5115.79 238.349 4991.71 220.3C4867.63 202.25 4844.33 175.167 4808 141.5C4719.54 59.5286 4584 83.4995 4299.5 203.5C3942.81 353.948 3603.65 157.123 3512 112C3420.35 66.8761 3364.05 115.012 3216 175.176C3067.95 235.341 2911.99 99.9706 2827.39 99.9706C2742.8 99.9706 2644.1 99.9704 2418.5 175.176C2192.91 250.382 2238.82 201.686 2172 147.5C2105.18 93.3145 2000.5 90.5004 1935 120C1840.77 162.441 1825 227 1736.5 182C1615.65 120.553 1690.5 156 1559.5 98.5C1433.47 43.1801 1281.5 129.692 1215.5 160.999C1145.15 194.371 993.473 143.317 878 110C814.551 84.931 713.728 53.781 617.5 99.9706C492.5 159.971 437.484 190.217 211.888 175.176Z"
      fill="url(#paint0_linear_8886_22042)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_8886_22042"
        x1="1791.43"
        y1="557.168"
        x2="2853.58"
        y2="-2728.02"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#13ADCF" />
        <stop offset="1" stopColor="#3FD5E7" />
      </linearGradient>
    </defs>
  </svg>
)

export default WaveBlueBg
