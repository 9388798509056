"use client"

import React from "react"
import clsx from "clsx"
import { useTranslations } from "next-intl"

import { useSearchParams } from "next/navigation"
import { AdviqoLink, Box, LinkButton } from "../_common"
import PersonCircleIcon from "../_common/QuesticoIcon/PersonCircleIcon"
import InfoIcon from "../_common/QuesticoIcon/InfoIcon"
import { PageSlug } from "../../types"
import { useRuntimeConfig } from "../../hooks/runtimeConfig"
import { cleanupAuthParams } from "./utils/cleanupAuthParams"
import { AuthModuleForms, IAuthModuleBodyProps } from "./types"
import classes from "./AuthModuleFooter.module.scss"

type IAuthModuleFooterProps = Pick<
  IAuthModuleBodyProps,
  "activeForm" | "redirectToAuthForm" | "registrationFooterText"
>

const AuthModuleFooter: React.FC<IAuthModuleFooterProps> = ({
  activeForm,
  redirectToAuthForm,
  registrationFooterText,
}) => {
  const searchParams = useSearchParams()
  const translate = useTranslations()
  const { getRuntimeConfig } = useRuntimeConfig()
  const { ADVICE_PORTAL_URL } = getRuntimeConfig()

  const isRegistrationForm = activeForm === AuthModuleForms.REGISTRATION

  const showLoginFooter =
    activeForm === AuthModuleForms.LOGIN ||
    activeForm === AuthModuleForms.FORGET_PASSWORD_EMAIL ||
    activeForm === AuthModuleForms.FORGET_PASSWORD_PASSWORD

  return (
    <>
      {showLoginFooter && (
        <Box className={classes.footer}>
          <Box className={clsx(classes.footerRow, classes.footerRowBg)}>
            <Box>
              <Box component="p" className={classes.footerEntryText}>
                {translate.rich("footerListLoginTitle", {
                  break: () => <br />,
                })}
              </Box>
            </Box>
            <Box>
              <LinkButton
                label="Zur Registrierung"
                testId="registration-link"
                onClick={(e) => {
                  e.preventDefault()
                  redirectToAuthForm({
                    pathname: `/${PageSlug.REGISTRATION}`,
                    query: cleanupAuthParams(searchParams),
                  })
                }}
              />
            </Box>
          </Box>
          <Box className={classes.footerRow}>
            <Box>
              <Box component="ul" className={classes.footerList}>
                <Box
                  component="li"
                  className={clsx(
                    classes.footerListItem,
                    classes.footerListItemCenter
                  )}
                >
                  <PersonCircleIcon className={classes.icon} />
                  {translate.rich("beraterLinkLoginText", {
                    linkItem: (text) => (
                      <AdviqoLink
                        to={ADVICE_PORTAL_URL}
                        target="_blank"
                        className={classes.link}
                        data-testid="berater-link-login"
                      >
                        {text}
                      </AdviqoLink>
                    ),
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {isRegistrationForm && (
        <Box className={clsx(classes.footer, classes.footerSpacer)}>
          <Box className={clsx(classes.footerRow)}>
            <Box>
              <Box component="p" className={classes.footerEntryText}>
                {registrationFooterText}
              </Box>
            </Box>
            <Box>
              <LinkButton
                label="Weiter zum Login"
                testId="login-link"
                onClick={(e) => {
                  e.preventDefault()
                  redirectToAuthForm({
                    pathname: `/${PageSlug.LOGIN}`,
                    query: cleanupAuthParams(searchParams),
                  })
                }}
              />
            </Box>
          </Box>
          <Box className={clsx(classes.footerRow, classes.footerRowBg)}>
            <Box>
              <Box component="p" className={classes.footerListTitle}>
                {translate("registration.footer.title")}
              </Box>
            </Box>
            <Box>
              <Box component="ul" className={classes.footerList}>
                <Box component="li" className={classes.footerListItem}>
                  <Box component="span" className={classes.icon}>
                    <InfoIcon width={14} height={14} />
                  </Box>
                  {translate("registration.footer.firstEmailDetails")}
                </Box>
                <Box component="li" className={classes.footerListItem}>
                  <Box component="span" className={classes.icon}>
                    <InfoIcon width={14} height={14} />
                  </Box>
                  {translate("registration.footer.secondEmailDetails")}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

export default AuthModuleFooter
