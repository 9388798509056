import React from "react"
import { IIconProps } from "../../_common/QuesticoIcon/types"

const Method20000405Icon: React.FC<IIconProps> = ({
  height = "100%",
  width = "100%",
}) => (
  <svg width={width} height={height} viewBox="0 0 47 51">
    <ellipse cx="25.4128" cy="26.2823" rx="17.819" ry="18.71" fill="#A4F0C1" />
    <path
      d="M39.9249 20.8589C37.8205 14.0879 31.6562 9.53235 24.5732 9.53235V11.0882C30.9734 11.0882 36.5479 15.2019 38.4474 21.3194L39.9311 20.8589H39.9249Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
    <path
      d="M4.98166 13.7455C6.45909 11.0445 8.47038 8.63609 10.8603 6.71307C13.2503 4.78382 16.0127 3.35244 18.949 2.48739L18.502 1C15.392 1.96462 12.4681 3.54536 9.96643 5.62398C7.46473 7.70259 5.37896 10.2728 3.86428 13.148C2.3496 16.0233 1.40603 19.191 1.10806 22.4147C0.803885 25.6384 1.1453 28.9181 2.08887 32.0049L3.03245 31.7186C2.16958 28.7376 1.89022 25.5948 2.22544 22.5267C2.56065 19.4523 3.50422 16.4527 4.98166 13.7517V13.7455Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
    <path
      d="M13.3932 41.1719L14.3306 39.9335C9.59414 36.3363 6.87517 30.8598 6.87517 24.9102C6.87517 14.5234 15.3052 6.07824 25.6596 6.07824C36.0141 6.07824 44.4441 14.5296 44.4441 24.9102C44.4441 30.7228 41.8431 36.0999 37.2929 39.697L35.2009 39.7654H17.093V41.3213H35.2257L37.8453 41.2341L38.044 41.0786C43.0971 37.1827 45.9961 31.2892 45.9961 24.904C45.9961 13.6646 36.8707 4.51617 25.6596 4.51617C14.4486 4.51617 5.32324 13.6646 5.32324 24.904C5.32324 31.3452 8.26569 37.2698 13.3932 41.1657V41.1719Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
    <path
      d="M29.0801 43.568H11.2578V45.1238H29.0801V43.568Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
    <path
      d="M42.6687 48.4658H8.61328V50.0217H42.6687V48.4658Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
    <path
      d="M40.0741 43.568H33.0098V45.1238H40.0741V43.568Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
  </svg>
)

export default Method20000405Icon
