import clsx from "clsx"
import WaveBlueBg from "./bg/waveBlue"
import { Box } from "../_common"
import { IUseSwipable } from "./types"
import WaveLightBlueBg from "./bg/waveLightBlue"
import classes from "./WizardWave.module.scss"

type IWizardWave = Pick<IUseSwipable, "activeStepIdx" | "stepsAmount">

const BLUE_WAVE_OFFSET = "30vw"
const LIGHT_BLUE_WAVE_OFFSET = "5vw"

export const WizardWave: React.FC<IWizardWave> = ({
  stepsAmount,
  activeStepIdx,
}) => {
  return (
    <>
      <Box
        className={clsx(classes.wave, classes.lightBlue)}
        sx={{
          width: `calc(100vw * ${stepsAmount} + ${LIGHT_BLUE_WAVE_OFFSET})`,
          left: `calc(-${LIGHT_BLUE_WAVE_OFFSET} + 100vw * ${
            activeStepIdx * -1
          })`,
        }}
      >
        <WaveLightBlueBg className={clsx(classes.wave)} />
      </Box>

      <Box
        className={clsx(classes.wave, classes.blue)}
        sx={{
          width: `calc(100vw * ${stepsAmount} - ${BLUE_WAVE_OFFSET})`,
          left: `calc(${BLUE_WAVE_OFFSET} + 100vw * ${activeStepIdx * -1})`,
        }}
      >
        <WaveBlueBg className={clsx(classes.wave)} />
      </Box>
    </>
  )
}
