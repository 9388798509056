import React from "react"
import { IIconProps } from "../../_common/QuesticoIcon/types"

const Topic20009100Icon: React.FC<IIconProps> = ({
  height = "100%",
  width = "100%",
}) => (
  <svg width={width} height={height} viewBox="0 0 47 47">
    <path
      d="M6.89941 42.1667V27.6852L7.33061 25.5555L23.9315 38.1204L41.6104 25.7685V43.0185L39.2389 45.1481H9.05538L7.9774 44.0833L6.89941 42.1667Z"
      fill="#00B4CB"
    />
    <path
      d="M14.0133 1.70372L15.9537 0.851873L17.4629 1.70372C20.2225 -0.312295 20.9124 0.863717 20.9124 1.70372C21.7747 4.42965 18.972 6.38891 17.4629 7.0278C13.3234 5.3241 13.4384 2.76854 14.0133 1.70372Z"
      fill="#A4F0C1"
    />
    <path
      d="M14.6607 16.3982C18.8002 11.287 22.7097 14.2685 24.147 16.3982C28.2864 11.9685 32.1959 14.5525 33.6332 16.3982C37.7727 20.9982 29.0338 28.2531 24.147 31.3056C13.2809 25.0019 13.2953 18.7407 14.6607 16.3982Z"
      fill="#A4F0C1"
    />
    <path
      d="M39.7587 9.35207L41.8816 9.30995L42.9384 10.6731C46.2735 9.87167 46.4478 11.2199 46.1181 11.9942C45.8431 14.8372 42.4906 15.5698 40.8487 15.5808C37.7017 12.4251 38.8108 10.1134 39.7587 9.35207Z"
      fill="#A4F0C1"
    />
    <path
      d="M30.7832 18.4206C30.3861 17.5505 29.5868 17.0048 28.6934 17.0048V15.7177C30.0831 15.7177 31.3736 16.5724 31.9744 17.8903L30.7832 18.4154V18.4206Z"
      fill="#404041"
    />
    <path
      d="M24.2677 38.6071C23.6721 38.6071 23.0713 38.4166 22.5854 38.0408L9.8584 28.0994L10.6682 27.0903L23.39 37.0317C23.8967 37.423 24.6334 37.423 25.1454 37.0317L37.8672 27.219L38.6718 28.2333L25.95 38.0459C25.4641 38.4217 24.8633 38.6122 24.2677 38.6122V38.6071Z"
      fill="#404041"
    />
    <path
      d="M38.2438 46H10.2925C8.11389 46 6.34277 44.2547 6.34277 42.1079V24.9898C6.34277 22.843 8.11389 21.0977 10.2925 21.0977H11.8129V22.3848H10.2925C8.83488 22.3848 7.64891 23.5535 7.64891 24.9898V42.1079C7.64891 43.5443 8.83488 44.7129 10.2925 44.7129H38.2438C39.7015 44.7129 40.8875 43.5443 40.8875 42.1079V24.9898C40.8875 23.5535 39.7015 22.3848 38.2438 22.3848H36.7287V21.0977H38.2438C40.4225 21.0977 42.1936 22.843 42.1936 24.9898V42.1079C42.1936 44.2547 40.4225 46 38.2438 46Z"
      fill="#404041"
    />
    <path
      d="M14.7179 35.2213L9.95605 38.9981L10.775 40.0008L15.5369 36.224L14.7179 35.2213Z"
      fill="#404041"
    />
    <path
      d="M33.819 35.2223L33 36.225L37.7578 39.9986L38.5768 38.9959L33.819 35.2223Z"
      fill="#404041"
    />
    <path
      d="M24.2675 32.1974L23.6772 31.8422C23.254 31.5899 13.3691 25.6076 13.3691 19.1053C13.3691 15.7847 16.1068 13.0869 19.4766 13.0869C21.3627 13.0869 23.1181 13.9416 24.2675 15.3728C25.4169 13.9416 27.1724 13.0869 29.0584 13.0869C32.4283 13.0869 35.1659 15.7898 35.1659 19.1053C35.1659 25.6076 25.2811 31.5899 24.8579 31.8422L24.2675 32.1974ZM19.4766 14.374C16.8278 14.374 14.6753 16.4951 14.6753 19.1053C14.6753 24.5985 23.3062 30.0969 24.2675 30.689C25.2289 30.0969 33.8598 24.5934 33.8598 19.1053C33.8598 16.4951 31.7073 14.374 29.0584 14.374C27.303 14.374 25.6834 15.3213 24.8422 16.84L24.2675 17.8749L23.6928 16.84C22.8465 15.3162 21.2321 14.374 19.4766 14.374Z"
      fill="#404041"
    />
    <path
      d="M7.06842 18.3537L6.56164 18.1477C5.95559 17.9058 0.610885 15.6817 0.046634 12.2632C-0.261614 10.4047 1.02362 8.64397 2.91491 8.34022C3.84488 8.19092 4.78007 8.4226 5.52196 8.95802C6.04963 8.22181 6.86466 7.70698 7.79463 7.55768C8.70893 7.41353 9.62322 7.62461 10.3756 8.16003C11.1279 8.69546 11.6242 9.48315 11.7705 10.3841C12.3348 13.818 7.98272 17.5814 7.48638 18.0036L7.06842 18.3537ZM3.46871 9.58611C3.35377 9.58611 3.23883 9.59641 3.12389 9.61185C1.94837 9.80234 1.14379 10.8989 1.3371 12.0573C1.68714 14.1887 4.95248 16.073 6.82287 16.8607C7.6379 16.1296 10.8876 13.0406 10.4853 10.59C10.3912 10.0289 10.083 9.53978 9.61277 9.20514C9.14256 8.8705 8.57309 8.73664 8.00361 8.82931C7.21993 8.95287 6.57209 9.48829 6.31086 10.2245L5.9138 11.3365L5.17714 10.4047C4.7644 9.87956 4.13223 9.58096 3.46871 9.58096V9.58611Z"
      fill="#404041"
    />
    <path
      d="M17.8313 8.17034L17.3872 7.92323C16.9483 7.6864 13.1919 5.56531 13.0874 2.92938C13.0247 1.45182 14.195 0.205934 15.6944 0.144155C16.3788 0.118413 17.0267 0.33979 17.5335 0.756802C18.0037 0.303752 18.6358 0.0308925 19.315 2.7481e-06C20.8092 -0.0720733 22.0788 1.09144 22.1415 2.569C22.2512 5.21522 18.6672 7.62462 18.2597 7.89234L17.8365 8.17034H17.8313ZM15.7989 1.43123C15.7989 1.43123 15.7623 1.43123 15.7414 1.43123C14.963 1.46212 14.3569 2.1108 14.3883 2.8779C14.451 4.38635 16.6557 5.99776 17.7686 6.65675C18.8239 5.91024 20.8928 4.13408 20.8301 2.62048C20.7988 1.85339 20.1301 1.25104 19.362 1.28708C18.8448 1.30767 18.3851 1.60627 18.1552 2.05932L17.6223 3.11472L17.0058 2.10565C16.7498 1.68349 16.29 1.42608 15.7937 1.42608L15.7989 1.43123Z"
      fill="#404041"
    />
    <path
      d="M40.877 16.6444L40.5426 16.2635C40.2187 15.8928 37.4235 12.6339 38.2438 10.1164C38.4632 9.43682 38.9439 8.88081 39.5917 8.55131C40.2396 8.22697 40.9762 8.16519 41.6659 8.38142C42.3137 8.58735 42.8466 9.01466 43.181 9.57582C43.7818 9.31326 44.4715 9.26693 45.1193 9.47286C45.8089 9.68909 46.3732 10.1627 46.7023 10.8011C47.0367 11.4395 47.0942 12.1603 46.8747 12.845C46.0545 15.3625 41.8487 16.4179 41.3733 16.526L40.8822 16.6444H40.877ZM40.8299 9.54494C40.6105 9.54494 40.3859 9.59642 40.1873 9.69938C39.853 9.86928 39.6022 10.1576 39.4872 10.5128C39.017 11.9543 40.5269 14.2144 41.342 15.2081C42.5958 14.8631 45.1559 13.8952 45.6261 12.4537C45.741 12.0985 45.7097 11.7227 45.5373 11.3932C45.3649 11.0637 45.0723 10.8166 44.7118 10.7033C44.2207 10.5489 43.6825 10.6724 43.3064 11.0225L42.4391 11.8359L42.2145 10.6776C42.1152 10.1782 41.7495 9.77146 41.2584 9.61186C41.1173 9.56553 40.971 9.54494 40.8247 9.54494H40.8299Z"
      fill="#404041"
    />
    <path
      d="M29.587 10.7548L29.2056 10.4716C28.913 10.2554 26.3478 8.28875 26.5515 6.28607C26.609 5.7249 26.8859 5.21522 27.33 4.85484C27.7741 4.49446 28.3331 4.32972 28.9078 4.38635C29.3885 4.43268 29.8326 4.63861 30.1774 4.95781C30.5744 4.71069 31.0499 4.59743 31.5358 4.64376C32.7165 4.76217 33.5838 5.80213 33.4636 6.96564C33.2599 8.96833 30.355 10.3944 30.0206 10.554L29.587 10.7548ZM28.6936 5.66312C28.5003 5.66312 28.3122 5.73005 28.1555 5.85361C27.9778 5.99776 27.8734 6.1934 27.8472 6.41477C27.748 7.3878 29.0071 8.64399 29.7385 9.24634C30.5744 8.79843 32.0582 7.81511 32.1575 6.83693C32.2045 6.37874 31.8649 5.96687 31.3947 5.92054C31.0865 5.8845 30.7834 6.02865 30.611 6.28092L29.9371 7.25395L29.4721 6.16766C29.3519 5.8845 29.0855 5.68887 28.7772 5.65798C28.7511 5.65798 28.7197 5.65798 28.6936 5.65798V5.66312Z"
      fill="#404041"
    />
  </svg>
)

export default Topic20009100Icon
