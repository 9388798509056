import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"

const PersonCircleIcon: React.FC<IIconProps> = ({
  color = "currentColor",
  className,
}) => {
  const { isQuesticoBrand } = useCheckBrand()
  return (
    <IconBase className={className}>
      {isQuesticoBrand ? (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="9.25" stroke={color} strokeWidth="1.5" />
          <mask
            id="mask0_187_38"
            maskUnits="userSpaceOnUse"
            x="2"
            y="2"
            width="20"
            height="20"
          >
            <circle
              cx="12"
              cy="12"
              r="9.25"
              fill="white"
              stroke="white"
              strokeWidth="1.5"
            />
          </mask>
          <g mask="url(#mask0_187_38)" />
          <path
            d="M17.4271 19.0833V17.9281L14.2725 16.1495C13.9365 15.9603 13.8436 15.5066 14.097 15.2165C15.095 14.0728 15.1983 12.1574 15.1424 10.9208C15.0713 9.3513 13.9013 8.02452 12.3398 7.85175C12.1292 7.82843 11.9985 7.83378 11.9985 7.83378C11.9985 7.83378 11.8677 7.82843 11.6571 7.85175C10.0957 8.02452 8.9256 9.3513 8.85451 10.9208C8.7987 12.1574 8.9019 14.0728 9.89995 15.2165C10.1534 15.5066 10.0605 15.9603 9.72488 16.1495L6.56982 17.9281V19.0833"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="square"
          />
        </svg>
      ) : (
        <svg width="100%" height="100%" fill="none" viewBox="0 0 24 24">
          <g
            clipPath="url(#PersonCircleIcon)"
            stroke={color}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M12 20.308a8.308 8.308 0 1 0 0-16.616 8.308 8.308 0 0 0 0 16.616Z" />
            <path d="M12 12c1.25 0 2.262-1.182 2.262-2.64 0-1.458-1.013-2.64-2.262-2.64S9.74 7.902 9.74 9.36C9.739 10.818 10.75 12 12 12ZM6.102 17.852A7.566 7.566 0 0 1 12 15.02a7.538 7.538 0 0 1 5.898 2.833" />
          </g>
          <defs>
            <clipPath id="PersonCircleIcon">
              <path fill="#fff" transform="translate(3 3)" d="M0 0h18v18H0z" />
            </clipPath>
          </defs>
        </svg>
      )}
    </IconBase>
  )
}

export default PersonCircleIcon
