import React from "react"
import clsx from "clsx"

import {
  IAdvisor,
  ProductFlowStep,
  ClientTrackingEventName,
} from "../../../types"
import Avatar from "../../Experts/Avatar"
// import Promotion from "../../Experts/ExpertProfile/Promotion"
// import { getPromotionText } from "../../Experts/ExpertProfile/utils"
// import { getPromotedProductInfo } from "../../Experts/Products/promotedProductUtils"
import StickableProducts from "../../Experts/Products/StickableProducts"
import { Box, Typography } from "../../_common"
import { IUseWizardTracking } from "../types"
import ExpertStats from "./ExpertStats"
import classes from "./ExpertCard.module.scss"
import RawHTML from "../../Experts/ExpertProfile/RawHTML"
import { useSearchParams } from "next/navigation"
import { ProductType } from "../../Experts/Filters/types"

type IExpertCard = Pick<IUseWizardTracking, "trackWizardStep"> & {
  expert: IAdvisor
}

export const ExpertCard: React.FC<IExpertCard> = ({
  expert,
  trackWizardStep,
}) => {
  const {
    listing_number: listingNumber,
    avatar,
    name,
    products,
    description,
    status,
    promotion,
    rating,
    sign,
    readings_count: readingsCount,
  } = expert
  const searchParams = useSearchParams()
  const productType = (searchParams.get("productType") || "ALL") as ProductType
  const displayedProduct =
    productType === "ALL"
      ? products
      : [
          products.find(
            (product) =>
              productType &&
              product.type.toLowerCase() === productType.toLowerCase()
          ) || products[0],
        ]

  // const { promotion } = getPromotedProductInfo(products)

  // const promotionData = getPromotionText(promotion)

  return (
    <Box className={classes.root}>
      <Avatar
        photo={avatar}
        displayName={name}
        className={clsx(classes.avatar, classes.row)}
      />

      <Typography className={clsx(classes.displayName, classes.row)}>
        {name}
      </Typography>
      <ExpertStats rating={rating} readingsCount={readingsCount} sign={sign} />
      {/* <Promotion
        products={products}
        promotionData={promotionData}
        promotion={promotion}
        rootClassName={clsx(classes.promotion, classes.row)}
      /> */}
      <div className={classes.divider} />
      <RawHTML text={description} />
      <StickableProducts
        promotion={promotion}
        stickableClassName={classes.productsRoot}
        listingNo={listingNumber}
        offeredProducts={displayedProduct}
        photo={avatar}
        displayName={name}
        initSrc="Profile"
        testId="experts-products"
        productPriceClassName={classes.productPrice}
        advisorStatus={status}
        stepToSkip={ProductFlowStep.checkout}
        onProductClick={() =>
          trackWizardStep({
            eventName: ClientTrackingEventName.WIZARD_COMPLETED,
          })
        }
        productsListClassName={classes.productsList}
      />
    </Box>
  )
}
