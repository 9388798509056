"use client"

import React, { useCallback, useEffect } from "react"
import clsx from "clsx"
import { useRouter, useSearchParams } from "next/navigation"

import { AdviqoButton, Box } from "../../_common"
import Input from "../components/Input"
import useForm from "../hooks/useForm"
import Message from "../components/Message/Message"
import {
  AuthModuleForms,
  IAuthModuleBodyProps,
  IMessage,
  TUseFormValues,
} from "../types"
import { useAuthentication } from "../../../hooks/authentication"
import { QUERY_TOKEN_VERIFICATION } from "../consts"
import { useTracking } from "../../../hooks/tracking"
import { CustomFrontendError, PageSlug } from "../../../types"
import { clientTrackingAuth } from "../../../hooks/authentication/trackingUtils"
import { strings } from "../strings"
import classes from "./ForgetPasswordPasswordForm.module.scss"

type IForgetPasswordPasswordFormProps = Pick<
  IAuthModuleBodyProps,
  "redirectToAuthForm"
>

const ForgetPasswordPasswordForm: React.FC<IForgetPasswordPasswordFormProps> = ({
  redirectToAuthForm,
}) => {
  const { updatePassword, validateResetPasswordLink } = useAuthentication()
  const { trackEvent } = useTracking()

  const { replace } = useRouter()
  const token = useSearchParams().get("token")
  const handleFormSubmit = useCallback(
    async (values: TUseFormValues): Promise<IMessage | void> => {
      const passwordNew = values["password-new"]?.value
      const passwordConfirm = values["password-confirm"]?.value

      if (!passwordNew || passwordNew !== passwordConfirm) {
        clientTrackingAuth(
          undefined,
          {
            code: CustomFrontendError.PASSWORDS_DONT_MATCH,
            message: strings.PASSWORDS_DONT_MATCH,
            reason: "system"
          },
          AuthModuleForms.FORGET_PASSWORD_PASSWORD,
          trackEvent
        )

        return {
          type: "error",
          summary: strings.PASSWORDS_DONT_MATCH,
        }
      }

      const errorMessage = await updatePassword(passwordNew, token as string)

      if (errorMessage) {
        replace(
          `/${PageSlug.PASSWORD_RECOVERY}?${QUERY_TOKEN_VERIFICATION}=failed`
        )
        return {
          type: "error",
          summary: errorMessage,
        }
      }

      replace(`/${PageSlug.LOGIN}?${QUERY_TOKEN_VERIFICATION}=success`)
    },
    [replace, token, trackEvent, updatePassword]
  )

  const { register, errors, isDisabled, message, submitHandler } = useForm(
    AuthModuleForms.FORGET_PASSWORD_PASSWORD,
    handleFormSubmit
  )

  useEffect(() => {
    async function validateResetToken() {
      if (token) {
        const errorCode = await validateResetPasswordLink(token as string)

        if (errorCode !== undefined) {
          redirectToAuthForm(
            `/${PageSlug.PASSWORD_RECOVERY}?${QUERY_TOKEN_VERIFICATION}=failed`
          )
        }
      } else {
        redirectToAuthForm(`/${PageSlug.PASSWORD_RECOVERY}`)
      }
    }

    validateResetToken()
  }, [redirectToAuthForm, replace, token, validateResetPasswordLink])

  return (
    <Box className={classes.root}>
      <form noValidate data-testid="password-recovery-form">
        {message && <Message message={message} />}
        <Box className={clsx(classes.formField, classes.formFieldBoxed)}>
          <Box component="p" className={classes.formFieldText}>
            Du musst dein Passwort ändern.
          </Box>
        </Box>
        <Box className={classes.formField}>
          <Input
            {...register({ name: "password-new", required: true })}
            type="password"
            label="Neues Passwort (mindestens 6 Zeichen)"
            placeholder="Neues Passwort (mindestens 6 Zeichen)"
            maxLength={30}
            error={errors["password-new"]}
            withVisibilityControl
            withIndicator
          />
        </Box>
        <Box className={classes.formField}>
          <Input
            {...register({ name: "password-confirm", required: true })}
            type="password"
            label="Passwort bestätigen"
            placeholder="Passwort bestätigen"
            maxLength={30}
            error={errors["password-confirm"]}
            withVisibilityControl
          />
        </Box>
        <Box className={classes.formField}>
          <AdviqoButton
            labelClassName={classes.submitButton}
            fullWidth
            type="submit"
            label="Speichern"
            labelCentered
            onClick={submitHandler}
            testId="forget-password-password-submit-button"
            disabled={isDisabled}
          />
        </Box>
      </form>
    </Box>
  )
}

export default ForgetPasswordPasswordForm
