"use client"

import React, { useEffect, useState, useCallback } from "react"
import { useRouter, usePathname, useSearchParams } from "next/navigation"

import { Box } from "../_common"
import AuthModuleHeader from "./AuthModuleHeader"
import AuthModuleBody from "./AuthModuleBody"
import AuthModuleFooter from "./AuthModuleFooter"
import {
  AuthModuleForms,
  IAuthModuleProps,
  IExpertEntry,
  TAuthModuleForm,
} from "./types"
import { awsTracking } from "../../utils/tracking"
import { getExpertEntryFromQuery } from "./utils/parse"
import { LOCAL_STORAGE_KEYS } from "../../types"
import { useFingerprints } from "../../hooks/fingerprints"
import { storage } from "../../utils/localStorage"
import {
  cleanupAuthParams,
  getFormNameByPath,
  hasAuthParams,
} from "./utils/cleanupAuthParams"
import classes from "./AuthModule.module.scss"

const AuthModule: React.FC<IAuthModuleProps> = ({
  loginTitle,
  registrationTitle,
  authForm,
  successLoginRedirectPage,
  successRegisterRedirectPage,
  loginFormTitle,
  loginFormPlaceholder,
  standalone = false,
  registrationFooterText,
}) => {
  const [activeForm, setActiveForm] = useState<TAuthModuleForm>(
    AuthModuleForms.LOGIN
  )
  const [expertEntry, setExpertEntry] = useState<IExpertEntry | null>(null)
  const { push, replace } = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const { onAuthFormChange } = useFingerprints()

  const redirectToAuthForm = useCallback(
    (redirectTo: string | { query: URLSearchParams; pathname: string }) => {
      if (standalone) {
        if (typeof redirectTo === "string") {
          setActiveForm(getFormNameByPath(redirectTo, authForm))
        } else {
          let newQuery: URLSearchParams

          if (hasAuthParams(redirectTo.query)) {
            newQuery = new URLSearchParams()

            redirectTo.query.forEach((q) => {
              newQuery.append(q, redirectTo.query.get(q)!)
            })
          } else {
            newQuery = new URLSearchParams(cleanupAuthParams(searchParams))
          }

          replace(`${pathname}?${newQuery}`)

          setActiveForm(getFormNameByPath(redirectTo.pathname, authForm))
        }
      } else {
        if (typeof redirectTo === "string") {
          push(redirectTo)
        } else {
          push(`${redirectTo.pathname}?${redirectTo.query}`)
        }
      }
    },
    [authForm, push, searchParams, replace, standalone]
  )

  useEffect(() => {
    const { clean } = awsTracking
    clean()

    return clean
  }, [])

  useEffect(() => {
    setActiveForm(getFormNameByPath(pathname, authForm))
  }, [authForm, pathname])

  useEffect(() => {
    if (
      activeForm === AuthModuleForms.REGISTRATION &&
      !awsTracking.dataAlreadyPersisted()
    ) {
      awsTracking.persist({ tsStart: new Date().getTime() })
    }
  }, [activeForm])

  useEffect(() => {
    const authSuccessRedirectUrl = storage.getItem(
      LOCAL_STORAGE_KEYS.AUTH_SUCCESS_REDIRECT_URL
    )
    const urlSplit = authSuccessRedirectUrl?.split("?")

    if (urlSplit) {
      const [, queryString] = urlSplit
      if (queryString) {
        setExpertEntry(getExpertEntryFromQuery(queryString))
      }
    }
  }, [])

  useEffect(() => {
    if (activeForm) onAuthFormChange(activeForm)
  }, [activeForm, onAuthFormChange])

  if (!activeForm) {
    return null
  }

  return (
    <Box className={classes.root}>
      <AuthModuleHeader
        activeForm={activeForm}
        loginTitle={loginTitle}
        registrationTitle={registrationTitle}
      />
      <AuthModuleBody
        activeForm={activeForm}
        redirectToAuthForm={redirectToAuthForm}
        successLoginRedirectPage={successLoginRedirectPage}
        successRegisterRedirectPage={successRegisterRedirectPage}
        expertEntry={expertEntry}
        loginFormTitle={loginFormTitle}
        loginFormPlaceholder={loginFormPlaceholder}
        standalone={standalone}
      />
      <AuthModuleFooter
        activeForm={activeForm}
        redirectToAuthForm={redirectToAuthForm}
        registrationFooterText={registrationFooterText}
      />
    </Box>
  )
}

export default AuthModule
