import clsx from "clsx"
import React from "react"
import classes from "./StickableBox.module.scss"

interface IStickableBoxProps {
  stickableClassName?: string
  children: React.ReactNode
}

const StickableBox: React.FC<IStickableBoxProps> = ({
  children,
  stickableClassName,
}) => {
  const className = clsx(classes.sticky, stickableClassName)

  return <div className={className}>{children}</div>
}

export default StickableBox
