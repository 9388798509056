import React from "react"
import clsx from "clsx"

import { IMessage } from "../../types"
import { AdviqoLink } from "../../../_common"
import ErrorCircleSmallIcon from "../../../_common/QuesticoIcon/ErrorCircleSmallIcon"
import SuccessCircleIcon from "../../../_common/QuesticoIcon/SuccessCircleIcon"
import InfoIcon from "../../../_common/QuesticoIcon/InfoIcon"
import { TMessageType } from "../../../../types"
import classes from "./Message.module.scss"
import commonClasses from "@styles/common.module.scss"

interface IMessageProps {
  message: IMessage
}

type IAuthMessageLinkProps = React.ComponentProps<typeof AdviqoLink>

export const AuthMessageLink: React.FC<IAuthMessageLinkProps> = ({
  to,
  children,
  ...rest
}) => {
  return (
    <AdviqoLink to={to} className={classes.link} {...rest}>
      {children}
    </AdviqoLink>
  )
}

function getIcon(type: TMessageType) {
  switch (type) {
    case "error":
      return <ErrorCircleSmallIcon width={13} height={13} />
    case "success":
      return <SuccessCircleIcon width={13} height={13} />
    case "info":
      return <InfoIcon width={13} height={13} />
    default:
      return null
  }
}

const Message: React.FC<IMessageProps> = ({ message }) => {
  const messageClasses = clsx(classes.root, message && classes[message.type])

  return (
    <div className={messageClasses} data-testid="error-hint">
      <span className={classes.icon}>{getIcon(message.type)}</span>
      <span className={commonClasses["text-align--left"]}>
        {message.summary}
      </span>
    </div>
  )
}

export default Message
