import React from "react"

import { ProductPriceString } from "../../../_common"
import { IExpertEntry } from "../../types"
import classes from "./ExpertEntry.module.scss"

type IExpertEntryProps = IExpertEntry

const ExpertEntry: React.FC<IExpertEntryProps> = ({
  photo,
  displayName,
  price,
  priceSale,
}) => {
  return (
    <div className={classes.root}>
      <div className={classes.avatarWrapper}>
        <img
          src={photo}
          alt={displayName}
          data-testid="expert-image"
          className={classes.image}
        />
      </div>
      <div className={classes.info}>
        <div className={classes.expertName} data-testid="expert-name">
          {displayName}
        </div>
        <div data-testid="expert-price">
          <ProductPriceString
            price={price}
            priceSale={priceSale}
            allowHighlight
          />
        </div>
      </div>
    </div>
  )
}

export default ExpertEntry
