"use client"

import clsx from "clsx"
import React, { ComponentProps, useEffect, useState } from "react"
import SwipeableViews from "react-swipeable-views"
import { useAuthentication } from "../../hooks/authentication"
import { LOCAL_STORAGE_KEYS, ClientTrackingEventName } from "../../types"
import { storage } from "../../utils/localStorage"
import { Box } from "../_common"
import { useSwipable } from "./hooks/useSwipable"
import { useWizard } from "./hooks/useWizard"
import { useWizardTracking } from "./hooks/useWizardTracking"
import {
  WizardAuthScreen,
  WizardExpertScreen,
  WizardIntroScreen,
} from "./screens"
import WizardClose from "./WizardClose"
import { WizardCustomerSteps } from "./WizardCustomerSteps"
import { IWizardStepProps } from "./types"
import classes from "./Wizard.module.scss"

type IWizardProps = Omit<
  ComponentProps<typeof WizardIntroScreen>,
  "handleNext"
> &
  Pick<
    ComponentProps<typeof WizardAuthScreen>,
    "authScreenAuthModule" | "authScreenText"
  > &
  Pick<
    ComponentProps<typeof WizardClose>,
    "closeModalTitle" | "closeModalText"
  > &
  Omit<ComponentProps<typeof WizardExpertScreen>, "trackWizardStep"> & {
    steps: ComponentProps<typeof WizardCustomerSteps>["steps"]
    title: string
  }

const AUTH_SCREEN_IDX = 2

/**
 * From wizard contentful configuration we have 4 screens:
 * - intro
 * - steps
 * - auth
 * - expert
 *
 * Expert screen is not a part of swipable component.
 * Auth screen is currently not swipable.
 */
const SCREENS_AMOUNT = 3

function getScreensMap(items: IWizardStepProps[]): string[] {
  return ["intro", ...items.map((item) => item.type as string), "auth"]
}

function shouldRedirectToAuthScreen(isAuthenticated: boolean): boolean {
  if (
    isAuthenticated &&
    storage.getItem(LOCAL_STORAGE_KEYS.WIZARD_AUTHENTICATION)
  ) {
    storage.cleanUp(LOCAL_STORAGE_KEYS.WIZARD_AUTHENTICATION)

    return true
  }

  return false
}

const Wizard: React.FC<IWizardProps> = ({
  steps,
  introScreenTitle,
  introScreenText,
  introScreenActionTitle,
  authScreenAuthModule,
  authScreenText,
  expertScreenTitle,
  expertScreenTestExpertsListing,
  closeModalTitle,
  closeModalText,
  title,
}) => {
  const [isExpertFound, setIsExpertFound] = useState(false)

  const { isAuthenticated } = useAuthentication()

  const shouldRedirectToAuth = shouldRedirectToAuthScreen(isAuthenticated)

  const screensMap = getScreensMap(steps)

  const {
    activeStepIdx,
    isLastStep,
    handleNext,
    handleBack,
    handleStepChange,
    isForward,
  } = useSwipable(SCREENS_AMOUNT, shouldRedirectToAuth ? AUTH_SCREEN_IDX : 0)

  const { trackWizardStep, customerStepsRef } = useWizardTracking(
    title,
    screensMap,
    activeStepIdx,
    isExpertFound
  )

  useWizard(isLastStep, isAuthenticated, shouldRedirectToAuth, setIsExpertFound)

  useEffect(() => {
    trackWizardStep({ eventName: ClientTrackingEventName.WIZARD_STARTED })
  }, [])

  return (
    <Box className={classes.root} data-test-id="wizard-window">
      {/* @ts-ignore */}
      <SwipeableViews
        index={activeStepIdx}
        enableMouseEvents
        animateHeight
        className={clsx(classes.steps, {
          [classes.hide]: isExpertFound,
          [classes.isLastStep]: isLastStep,
        })}
        slideClassName={classes.step}
        onChangeIndex={(step: number) => {
          if (isForward(step)) {
            trackWizardStep({
              eventName: ClientTrackingEventName.WIZARD_STEP_COMPLETED,
            })
          }
          handleStepChange(step)
        }}
        disabled={isLastStep}
      >
        <WizardIntroScreen
          introScreenTitle={introScreenTitle}
          introScreenText={introScreenText}
          introScreenActionTitle={introScreenActionTitle}
          handleNext={() => {
            trackWizardStep({
              eventName: ClientTrackingEventName.WIZARD_STEP_COMPLETED,
            })
            handleNext()
          }}
        />

        <WizardCustomerSteps
          steps={steps}
          goToAuthScreen={handleNext}
          goToIntroScreen={handleBack}
          customerStepsRef={customerStepsRef}
          trackWizardStep={trackWizardStep}
          isCustomerStepsActive={activeStepIdx === 1}
        />

        <WizardAuthScreen
          authScreenAuthModule={authScreenAuthModule}
          authScreenText={authScreenText}
          isLastStep={isLastStep}
          trackWizardStep={trackWizardStep}
        />
      </SwipeableViews>

      <Box
        className={clsx({
          [classes.hide]: !isExpertFound,
        })}
      >
        <WizardExpertScreen
          expertScreenTitle={expertScreenTitle}
          expertScreenTestExpertsListing={expertScreenTestExpertsListing}
          trackWizardStep={trackWizardStep}
        />
      </Box>

      <WizardClose
        closeModalTitle={closeModalTitle}
        closeModalText={closeModalText}
        trackWizardStep={trackWizardStep}
      />
    </Box>
  )
}

export default Wizard
