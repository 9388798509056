import React from "react"
import clsx from "clsx"

import {
  AdviqoButton,
  Box,
  useTheme,
  Hidden,
  MobileStepper,
  ArrowBackIcon,
  ArrowForwardIcon,
} from "../_common"
import ChevronIcon from "../_common/QuesticoIcon/ChevronIcon"
import { IUseSwipable } from "./types"
import classes from "./WizardStepper.module.scss"

type IWizardStepper = Pick<
  IUseSwipable,
  "activeStepIdx" | "handleBack" | "handleNext" | "stepsAmount"
>

export const WizardStepper: React.FC<IWizardStepper> = ({
  stepsAmount,
  activeStepIdx,
  handleBack,
  handleNext,
}) => {
  const theme = useTheme()

  return (
    <MobileStepper
      steps={stepsAmount}
      position="static"
      activeStep={activeStepIdx}
      classes={{
        root: classes.stepperRoot,
        dots: classes.dots,
        dot: classes.dot,
        dotActive: classes.dotActive,
      }}
      backButton={
        <AdviqoButton
          round
          color="custom"
          size="small"
          testId="backBtn"
          buttonClassName={clsx(classes.swiperAction, classes.swiperBackAction)}
          icon={
            <>
              <Hidden mdDown>
                <ArrowBackIcon className={classes.swiperActionArrowBack} />
                <Box component="span">Zurück</Box>
              </Hidden>
              <Hidden mdUp>
                <ChevronIcon
                  color={theme.palette.contextualColors.uiSecondary}
                />
              </Hidden>
            </>
          }
          onClick={handleBack}
        />
      }
      nextButton={
        <AdviqoButton
          round
          color="custom"
          size="small"
          testId="nextBtn"
          buttonClassName={clsx(classes.swiperAction, classes.swiperNextAction)}
          icon={
            <>
              <Hidden mdDown>
                <Box component="span">Weiter</Box>
                <ArrowForwardIcon
                  className={classes.swiperActionArrowForward}
                />
              </Hidden>
              <Hidden mdUp>
                <ChevronIcon
                  color={theme.palette.contextualColors.uiSecondary}
                />
              </Hidden>
            </>
          }
          onClick={() => handleNext()}
        />
      }
    />
  )
}
