import clsx from "clsx"
import React from "react"
import { Box } from "../../_common"
import { IWizardStepProps } from "../types"
import classes from "./Step.module.scss"

type IPartialWizardStep = Partial<Pick<IWizardStepProps, "title">> & {
  rootClassName?: string
  children: React.ReactNode
}

export const WizardStep: React.FC<IPartialWizardStep> = ({
  title,
  rootClassName,
  children,
}) => {
  return (
    <Box className={clsx(classes.root, rootClassName)}>
      {title && (
        <Box component="h4" className={clsx(classes.title)}>
          {title}
        </Box>
      )}

      <Box className={classes.content}>{children}</Box>
    </Box>
  )
}
