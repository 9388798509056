import React from "react"
import { IIconProps } from "../../_common/QuesticoIcon/types"

const Mood1Icon: React.FC<IIconProps> = ({
  height = "100%",
  width = "100%",
}) => (
  <svg width={width} height={height} viewBox="0 0 45 45">
    <path
      d="M12.7986 22.4051C12.7986 17.0445 17.1602 12.6849 22.5212 12.6849C27.8796 12.6849 32.2414 17.0466 32.2414 22.4051C32.2414 27.7636 27.8819 32.1253 22.5212 32.1253C17.1602 32.1253 12.7986 27.7657 12.7986 22.4051Z"
      fill="#A4F0C1"
      stroke="#3D3C42"
      strokeWidth="1.4"
    />
    <path
      d="M22.5098 8.49486C21.878 8.49486 21.3643 7.98112 21.3643 7.3493V1.49957C21.3643 0.867752 21.878 0.354004 22.5098 0.354004C23.1416 0.354004 23.6554 0.867752 23.6554 1.49957V7.3493C23.6554 7.98112 23.1416 8.49486 22.5098 8.49486Z"
      fill="#3D3C42"
      stroke="#F6F6F6"
      strokeWidth="0.5"
    />
    <path
      d="M11.5928 33.3456L11.5936 33.3464C11.8099 33.5627 11.9298 33.8504 11.9298 34.1558C11.9298 34.462 11.8117 34.7495 11.5936 34.9676L7.4581 39.1031C7.24184 39.3193 6.95408 39.4392 6.64868 39.4392C6.34252 39.4392 6.05498 39.3211 5.83693 39.1031C5.39029 38.6564 5.39029 37.9286 5.83693 37.4819L9.97245 33.3464C10.1886 33.1303 10.4762 33.0103 10.7842 33.0103C11.0927 33.0103 11.3774 33.1283 11.5928 33.3456Z"
      fill="#3D3C42"
      stroke="#F6F6F6"
      strokeWidth="0.5"
    />
    <path
      d="M39.1885 5.70646L39.1893 5.70724C39.4056 5.9235 39.5255 6.21125 39.5255 6.51666C39.5255 6.82301 39.4073 7.11004 39.1901 7.3253L39.1893 7.32608L35.0538 11.4616C34.8375 11.6779 34.5498 11.7977 34.2444 11.7977H34.1702C33.892 11.7802 33.633 11.6643 33.4326 11.4639C32.986 11.0173 32.986 10.2894 33.4326 9.84275L37.5682 5.70724C37.7843 5.49113 38.0719 5.37109 38.3799 5.37109C38.6884 5.37109 38.9731 5.48913 39.1885 5.70646Z"
      fill="#3D3C42"
      stroke="#F6F6F6"
      strokeWidth="0.5"
    />
    <path
      d="M10.71 11.7977C10.4318 11.7802 10.1728 11.6643 9.97245 11.4639L5.83693 7.3284C5.39029 6.88177 5.39029 6.15387 5.83693 5.70724C6.05304 5.49113 6.34073 5.37109 6.64868 5.37109C6.95714 5.37109 7.24191 5.48913 7.45732 5.70646L7.45732 5.70646L7.4581 5.70724L11.5936 9.84275C11.8099 10.059 11.9298 10.3468 11.9298 10.6522C11.9298 10.9585 11.8116 11.2456 11.5944 11.4608L11.5936 11.4616C11.3774 11.6779 11.0896 11.7977 10.7842 11.7977H10.71Z"
      fill="#3D3C42"
      stroke="#F6F6F6"
      strokeWidth="0.5"
    />
    <path
      d="M33.4311 33.3472L33.4311 33.3472L33.4318 33.3464C33.6479 33.1303 33.9356 33.0103 34.2436 33.0103C34.552 33.0103 34.8368 33.1283 35.0522 33.3456L35.0522 33.3456L35.053 33.3464L39.1885 37.4819C39.6352 37.9286 39.6352 38.6564 39.1885 39.1031C38.9723 39.3193 38.6845 39.4392 38.3791 39.4392C38.0729 39.4392 37.7854 39.3211 37.5674 39.1031L33.4318 34.9676C33.2157 34.7515 33.0957 34.4638 33.0957 34.1558C33.0957 33.8474 33.2137 33.5626 33.4311 33.3472Z"
      fill="#3D3C42"
      stroke="#F6F6F6"
      strokeWidth="0.5"
    />
    <path
      d="M37.5499 22.555C36.918 22.555 36.4043 22.0413 36.4043 21.4095C36.4043 20.7777 36.918 20.2639 37.5499 20.2639H43.3996C44.0314 20.2639 44.5452 20.7777 44.5452 21.4095C44.5452 22.0413 44.0314 22.555 43.3996 22.555H37.5499Z"
      fill="#3D3C42"
      stroke="#F6F6F6"
      strokeWidth="0.5"
    />
    <path
      d="M1.62994 22.555C0.998124 22.555 0.484375 22.0413 0.484375 21.4095C0.484375 20.7777 0.998124 20.2639 1.62994 20.2639H7.47967C8.11149 20.2639 8.62524 20.7777 8.62524 21.4095C8.62524 22.0413 8.11149 22.555 7.47967 22.555H1.62994Z"
      fill="#3D3C42"
      stroke="#F6F6F6"
      strokeWidth="0.5"
    />
    <path
      d="M22.5098 44.4563C21.878 44.4563 21.3643 43.9425 21.3643 43.3107V37.461C21.3643 36.8292 21.878 36.3154 22.5098 36.3154C23.1416 36.3154 23.6554 36.8292 23.6554 37.461V43.3107C23.6554 43.9425 23.1416 44.4563 22.5098 44.4563Z"
      fill="#3D3C42"
      stroke="#F6F6F6"
      strokeWidth="0.5"
    />
  </svg>
)

export default Mood1Icon
