"use client"

import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { Dispatch, SetStateAction, useContext, useEffect } from "react"
import {
  storeAuthSuccessRedirect,
  storeWizardAuthentication,
} from "../../../hooks/authentication/storageUtils"
import { useUser } from "../../../hooks/user"
import FiltersContext from "../../Experts/Filters/context/FiltersContext"

const FAKE_SEARCH_DELAY = 4000

export const useWizard = (
  isLastStep: boolean,
  isAuthenticated: boolean,
  shouldRedirectToAuth: boolean,
  setIsExpertFound: Dispatch<SetStateAction<boolean>>
): null => {
  const { getPhoneNumber } = useUser()

  const { phoneNumber } = getPhoneNumber()

  const { applyFilter, setFiltersState } = useContext(FiltersContext)

  const { replace } = useRouter()
  const searchParams = useSearchParams()
  const pathname = usePathname()

  useEffect(() => {
    if (!shouldRedirectToAuth) {
      replace(pathname)
      setFiltersState()
    }
  }, [])

  useEffect(() => {
    if (isLastStep && !isAuthenticated) {
      storeAuthSuccessRedirect(pathname, searchParams, window.location.hash)
      storeWizardAuthentication()
    }
  }, [isAuthenticated, isLastStep, pathname, searchParams])

  useEffect(() => {
    if (phoneNumber && isLastStep) {
      applyFilter()
    }

    const timeout = setTimeout(() => {
      if (phoneNumber && isLastStep) {
        setIsExpertFound(true)
      }
    }, FAKE_SEARCH_DELAY)

    return () => {
      clearTimeout(timeout)
    }
  }, [isLastStep, phoneNumber, applyFilter, setIsExpertFound])

  return null
}
