import { TInputError } from "../types"
import { CustomFrontendError } from "../../../types"
import { ISimplifiedApiError } from "../../../utils/rest"
import { strings } from "../strings"

export const getFormFieldError = (
  inputError: TInputError
): ISimplifiedApiError | undefined => {
  if (inputError === "invalidPhoneOrEmail") {
    return {
      code: CustomFrontendError.WRONG_USERNAME,
      message: strings.INVALID_PHONE_OR_EMAIL,
      reason: "system"
    }
  }
  if (inputError === "isRequired") {
    return {
      code: CustomFrontendError.EMPTY_FIELD,
      message: strings.IS_REQUIRED,
      reason: "system"
    }
  }

  return undefined
}
