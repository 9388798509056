import clsx from "clsx"
import React from "react"

import { Box } from "../../_common"
import classes from "./Screen.module.scss"

interface IScreen {
  rootClassName?: string
  children: React.ReactNode
}

export const Screen: React.FC<IScreen> = ({ rootClassName, children }) => {
  return <Box className={clsx(classes.root, rootClassName)}>{children}</Box>
}
