import React from "react"
import { IIconProps } from "../../_common/QuesticoIcon/types"

const Method20000244Icon: React.FC<IIconProps> = ({
  height = "100%",
  width = "100%",
}) => (
  <svg width={width} height={height} viewBox="0 0 61 51">
    <ellipse cx="30.8555" cy="24.6345" rx="18" ry="18.5" fill="#87E5DB" />
    <path
      d="M59.4348 15.5169C59.0175 14.8474 58.4202 14.4096 57.8359 14.1071C56.6479 13.4762 55.4214 13.2444 54.2078 13.0577C51.9346 12.7423 49.6807 12.7745 47.446 12.9097C42.3667 5.90548 33.2418 2.51283 24.5151 5.1394C15.7884 7.76596 10.0348 15.6328 9.64309 24.2786C5.11598 26.8536 0.132946 30.5424 1.12827 33.8707C1.93095 36.5552 6.11771 37.2826 10.4136 37.2826C11.409 37.2826 12.3979 37.2376 13.3611 37.1796C17.3103 42.877 23.8537 46.1666 30.6026 46.1666C32.5868 46.1666 34.5839 45.8834 36.536 45.2975C45.1921 42.6903 51.2668 34.6174 51.4209 25.5854C52.6089 24.9095 53.7775 24.1949 54.9013 23.3966C55.8838 22.6949 56.8342 21.9417 57.7139 21.0726C58.5615 20.1778 59.4348 19.2057 59.788 17.7701C59.9614 17.0619 59.8458 16.1864 59.422 15.5233L59.4348 15.5169ZM2.66942 33.4072C2.27771 32.0874 3.94086 29.5188 9.64309 26.139C9.72015 27.8322 9.99626 29.5446 10.5036 31.2377C10.9659 32.7763 11.5952 34.2441 12.385 35.6217C5.83517 35.9307 3.06112 34.7076 2.66942 33.4007V33.4072ZM58.356 17.4546C58.1634 18.343 57.457 19.2572 56.6929 20.049C55.9095 20.8473 55.0169 21.5683 54.0858 22.2443C52.2236 23.5962 50.1944 24.755 48.1267 25.8236C43.9785 27.948 39.6183 29.6862 35.1811 31.1733C30.7375 32.654 26.2104 33.8707 21.6126 34.7526L21.8117 35.8149C26.4672 34.9651 31.0521 33.7741 35.56 32.3256C40.0614 30.8707 44.4858 29.1519 48.7367 27.0274C49.0835 26.8472 49.4302 26.6669 49.7834 26.4802C49.2569 34.4372 43.773 41.4349 36.0801 43.7525C27.9891 46.1924 19.089 42.9607 14.4335 35.9114C13.3932 34.3342 12.5905 32.6089 12.0447 30.787C8.98167 20.564 14.7866 9.7552 24.9839 6.68444C35.1811 3.61367 45.9627 9.43332 49.0257 19.6563L50.5604 19.1928C50.0531 17.4997 49.3468 15.916 48.4799 14.4611C50.3421 14.3646 52.1979 14.3581 54.0023 14.5899C55.1068 14.7444 56.237 14.9697 57.136 15.4461C57.5919 15.6714 57.9643 15.974 58.1762 16.3087C58.3881 16.6435 58.4524 17.0233 58.3625 17.4611L58.356 17.4546Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
    <path
      d="M53.6167 2.44204C54.0148 2.44204 54.3359 2.12015 54.3359 1.72102C54.3359 1.32188 54.0148 1 53.6167 1C53.2185 1 52.8975 1.32188 52.8975 1.72102C52.8975 2.12015 53.2185 2.44204 53.6167 2.44204Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
    <path
      d="M13.2904 44.8084C13.7053 44.8084 14.0417 44.4712 14.0417 44.0552C14.0417 43.6392 13.7053 43.302 13.2904 43.302C12.8754 43.302 12.5391 43.6392 12.5391 44.0552C12.5391 44.4712 12.8754 44.8084 13.2904 44.8084Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
    <path
      d="M6.22008 21.5683C6.66316 21.5683 7.01635 21.2078 7.01635 20.7701C7.01635 20.3323 6.65674 19.9718 6.22008 19.9718C5.78342 19.9718 5.42383 20.3323 5.42383 20.7701C5.42383 21.2078 5.78342 21.5683 6.22008 21.5683Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
    <path
      d="M53.4502 9.09853C53.4502 9.34316 53.6493 9.54272 53.8933 9.54272C54.1373 9.54272 54.3364 9.34316 54.3364 9.09853C54.3364 8.8539 54.1373 8.65433 53.8933 8.65433C53.6493 8.65433 53.4502 8.8539 53.4502 9.09853Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
    <path
      d="M8.92426 49.3339C8.6674 49.3339 8.46191 49.5399 8.46191 49.7974C8.46191 50.0549 8.6674 50.2609 8.92426 50.2609C9.18111 50.2609 9.3866 50.0549 9.3866 49.7974C9.3866 49.5399 9.18111 49.3339 8.92426 49.3339Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
    <path
      d="M5.48871 41.6795C5.23185 41.6795 5.02637 41.8855 5.02637 42.143C5.02637 42.4006 5.23185 42.6066 5.48871 42.6066C5.74557 42.6066 5.95105 42.4006 5.95105 42.143C5.95105 41.8855 5.74557 41.6795 5.48871 41.6795Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
    <path
      d="M46.2253 5.27451C46.4693 5.27451 46.6684 5.07494 46.6684 4.83031C46.6684 4.58568 46.4693 4.38611 46.2253 4.38611C45.9813 4.38611 45.7822 4.58568 45.7822 4.83031C45.7822 5.07494 45.9813 5.27451 46.2253 5.27451Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
    <path
      d="M14.9219 26.2227H16.5272C16.5272 19.5854 20.7718 13.8044 27.0841 11.8345L26.6089 10.2959C19.6223 12.4783 14.9219 18.8773 14.9219 26.2162V26.2227Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
  </svg>
)

export default Method20000244Icon
