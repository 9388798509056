import React from "react"
import clsx from "clsx"
import ExpertEntry from "./components/ExpertEntry"
import { Box, useMediaQuery, useTheme } from "../_common"
import { AuthModuleForms, IAuthModuleBodyProps } from "./types"
import AuthModuleForm from "./AuthModuleForm"
import { isLoginOrRegistration, getFormTitleText } from "./utils/title"
import classes from "./AuthModuleBody.module.scss"

const AuthModuleBody: React.FC<Omit<
  IAuthModuleBodyProps,
  "loginTitle" | "registrationTitle" | "registrationFooterText"
>> = ({
  activeForm,
  redirectToAuthForm,
  successLoginRedirectPage,
  successRegisterRedirectPage,
  expertEntry,
  loginFormTitle,
  loginFormPlaceholder,
  standalone,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const rootClasses = clsx(classes.root, {
    [classes.rootDesktop]: !isMobile,
  })

  const titleClasses = clsx(classes.title, {
    [classes.titleDesktop]: !isMobile,
  })

  const isLoginOrRegistrationForm =
    activeForm === AuthModuleForms.REGISTRATION ||
    activeForm === AuthModuleForms.LOGIN

  return (
    <>
      <Box className={rootClasses}>
        <Box
          className={classes.column}
          data-testid="registration-form-with-title"
        >
          {!isMobile && isLoginOrRegistration(activeForm) && (
            <Box component="h3" className={titleClasses}>
              {getFormTitleText(activeForm, loginFormTitle)}
            </Box>
          )}

          {isLoginOrRegistrationForm && expertEntry && (
            <ExpertEntry {...expertEntry} />
          )}

          <AuthModuleForm
            loginFormPlaceholder={loginFormPlaceholder}
            activeForm={activeForm}
            redirectToAuthForm={redirectToAuthForm}
            successLoginRedirectPage={successLoginRedirectPage}
            successRegisterRedirectPage={successRegisterRedirectPage}
            standalone={standalone}
          />
        </Box>
      </Box>
    </>
  )
}

export default AuthModuleBody
