import { IFilterOption } from "../../types"
import CallIcon from "../_common/QuesticoIcon/CallIcon"
import ChatIcon from "../_common/QuesticoIcon/ChatIcon"
import {
  Method20000244Icon,
  Method20000405Icon,
  Method20000407Icon,
  Method20000408Icon,
  Method20050500Icon,
  Mood1Icon,
  Mood2Icon,
  Mood3Icon,
  Topic20009100Icon,
  Topic20009200Icon,
  Topic20009300Icon,
  Topic20009400Icon,
} from "./icons"

export const renderIcon = (
  id: IFilterOption["id"],
  iconClassName?: string
): JSX.Element | null => {
  switch (id) {
    case "20009100":
      return <Topic20009100Icon />
    case "20009200":
      return <Topic20009200Icon />
    case "20009300":
      return <Topic20009300Icon />
    case "20009400":
      return <Topic20009400Icon />
    case "20000407":
      return <Method20000407Icon />
    case "20000405":
      return <Method20000405Icon />
    case "20000408":
      return <Method20000408Icon />
    case "20000244":
      return <Method20000244Icon />
    case "20050500":
      return <Method20050500Icon />
    case "CALL":
      return <CallIcon className={iconClassName} />
    case "CHAT":
      return <ChatIcon className={iconClassName} />
    case "mood1":
      return <Mood1Icon className={iconClassName} />
    case "mood2":
      return <Mood2Icon className={iconClassName} />
    case "mood3":
      return <Mood3Icon className={iconClassName} />
    default:
      return null
  }
}
