import React from "react"
import { IIconProps } from "../../_common/QuesticoIcon/types"

const Topic20009400Icon: React.FC<IIconProps> = ({
  height = "100%",
  width = "100%",
}) => (
  <svg width={width} height={height} viewBox="0 0 59 50">
    <path
      d="M32.2082 44.893L30.3687 49.0391C30.3687 49.0391 18.1658 48.5653 12.8932 46.7357C11.1606 46.134 10.5938 44.9908 10.5938 43.9716C10.5938 42.1289 12.9345 41.1944 15.6524 40.2862C21.171 38.4435 29.9088 36.6007 29.9088 36.6007L34.5076 36.14C34.5076 36.14 45.3007 39.2482 46.4645 40.7469C49.6837 44.893 45.5448 47.1964 44.1651 47.1964C44.0187 47.1964 42.4157 46.8166 40.4861 46.2751C39.2885 45.9404 37.9483 45.3311 36.807 45.3537L34.9675 46.2751L32.2082 44.893Z"
      fill="#00B4CB"
    />
    <path
      d="M55.8913 49.9605H1.16537C0.784326 49.9605 0.474609 49.6503 0.474609 49.2685C0.474609 48.8868 0.784326 48.5766 1.16537 48.5766H55.8913C56.2723 48.5766 56.5802 48.8868 56.5802 49.2685C56.5802 49.6503 56.2705 49.9605 55.8913 49.9605Z"
      fill="#3D3C42"
    />
    <path
      d="M37.179 50C36.0696 50 34.8007 49.9887 33.3441 49.9605C32.6327 49.9361 16.1145 49.3268 11.3505 46.8768C10.0553 46.2111 9.30825 44.9363 9.29699 43.3812C9.28572 41.7077 10.1379 40.1264 11.4199 39.4457C15.2417 37.4111 22.3257 35.7659 22.626 35.6963C22.9958 35.6098 23.3675 35.8429 23.452 36.2153C23.5364 36.5876 23.3055 36.958 22.9339 37.0426C22.8626 37.0595 15.7259 38.7161 12.0657 40.6641C11.2398 41.1041 10.6672 42.2173 10.6748 43.3699C10.6785 43.9716 10.855 45.0679 11.9793 45.6451C16.473 47.9561 33.2127 48.5709 33.3798 48.5766C37.5806 48.6556 42.2621 48.586 43.6567 48.3434C43.4409 47.9109 42.8271 47.1945 41.6332 47.1945C40.5708 47.1945 39.5347 46.8862 38.5342 46.5891C38.1156 46.465 37.7214 46.3484 37.3329 46.2544C36.7397 46.1133 36.5595 46.2262 36.2648 46.4198C35.927 46.6398 35.4145 46.9727 34.5342 46.7038C34.2883 46.6286 33.9222 46.4537 33.1883 46.0964C30.1794 44.6316 23.1329 41.2038 17.9446 43.0089C17.5842 43.1349 17.1919 42.9431 17.0662 42.5821C16.9404 42.221 17.1319 41.8281 17.4923 41.7021C23.2173 39.707 30.628 43.3116 33.7908 44.8516C34.2995 45.0998 34.7801 45.333 34.934 45.38C35.2005 45.4609 35.2475 45.4308 35.5121 45.2578C35.8988 45.0058 36.4826 44.6279 37.6539 44.908C38.0781 45.0096 38.5098 45.1374 38.9265 45.2615C39.8331 45.5304 40.7698 45.8106 41.6332 45.8106C43.3057 45.8106 44.4 46.779 44.8693 47.6853C45.1809 48.2889 45.2128 48.8417 44.9556 49.2046C44.7642 49.4754 44.3944 49.9962 37.1771 49.9962L37.179 50Z"
      fill="#3D3C42"
    />
    <path
      d="M22.78 37.0614C22.6599 37.0614 22.536 37.0294 22.4253 36.9636C22.0986 36.7681 21.9916 36.3431 22.1887 36.0159C23.482 33.8573 22.566 27.325 22.1043 24.9897C22.0311 24.6155 22.2732 24.2507 22.6467 24.1773C23.0222 24.104 23.3844 24.3466 23.4576 24.7208C23.6378 25.6346 25.1695 33.7276 23.3732 36.7286C23.2437 36.9448 23.0147 37.0633 22.7819 37.0633L22.78 37.0614Z"
      fill="#3D3C42"
    />
    <path
      d="M2.16606 35.4857C2.12665 35.4857 2.08535 35.4857 2.04593 35.4857C0.553662 35.4537 0.123814 34.7618 0.0243293 34.1864C-0.184025 32.9754 0.882149 32.2083 1.95771 32.0089C2.33312 31.9375 2.69164 32.1876 2.76109 32.5618C2.83055 32.9378 2.58277 33.297 2.20924 33.3665C2.20361 33.3665 1.31951 33.5734 1.38333 33.9532C1.56916 34.1864 2.50394 34.1544 4.16514 33.8667L4.2665 33.8498C4.96853 33.7295 5.23319 33.5978 5.29138 33.5659C5.41715 33.4474 5.58608 33.3797 5.76253 33.3797H16.0432C17.177 33.3797 18.1756 32.6953 18.5867 31.6366C18.5942 31.616 18.6035 31.5953 18.6129 31.5746L22.1606 24.544C22.3333 24.2037 22.7481 24.0683 23.0879 24.2394C23.4276 24.4124 23.5646 24.8279 23.3919 25.1683L19.8574 32.1707C19.2305 33.7464 17.7363 34.7618 16.0413 34.7618H5.98402C5.66116 34.9479 5.16562 35.0983 4.50301 35.213L4.40353 35.23C3.73529 35.3447 2.90938 35.4876 2.16794 35.4876L2.16606 35.4857Z"
      fill="#3D3C42"
    />
    <path
      d="M29.3182 43.8983C29.0348 43.8983 28.7682 43.7216 28.6687 43.4376C28.5411 43.0785 28.7307 42.6836 29.0892 42.5557C33.5022 40.9951 37.1906 40.7093 40.055 41.7077C40.4154 41.8337 40.605 42.2267 40.4811 42.5877C40.3554 42.9487 39.963 43.1386 39.6026 43.0145C37.0405 42.1233 33.658 42.4072 29.5491 43.8607C29.474 43.887 29.3952 43.9002 29.3201 43.9002L29.3182 43.8983Z"
      fill="#3D3C42"
    />
    <path
      d="M45.8835 46.9539C45.6339 46.9539 45.3917 46.8166 45.2697 46.5778C45.0952 46.2393 45.2303 45.8219 45.5682 45.647C46.6925 45.0698 46.8671 43.9735 46.8727 43.3718C46.8803 42.2192 46.3096 41.106 45.4818 40.666C41.8216 38.718 34.6849 37.0595 34.6136 37.0426C34.2419 36.958 34.0111 36.5857 34.0955 36.2153C34.18 35.8429 34.5517 35.6098 34.9214 35.6963C35.2218 35.7659 42.3058 37.4111 46.1275 39.4457C47.4077 40.1264 48.2618 41.7077 48.2505 43.3812C48.2392 44.9381 47.4903 46.2111 46.197 46.8768C46.0956 46.9294 45.9886 46.952 45.8835 46.952V46.9539Z"
      fill="#3D3C42"
    />
    <path
      d="M34.7686 37.0614C34.5339 37.0614 34.3068 36.9411 34.1773 36.7267C32.3791 33.7257 33.9107 25.6327 34.0928 24.7189C34.166 24.3447 34.5358 24.1022 34.9037 24.1755C35.2773 24.2488 35.5213 24.6136 35.4462 24.9878C34.9844 27.3232 34.0703 33.8592 35.3617 36.016C35.5588 36.3431 35.4518 36.7681 35.1252 36.9636C35.0145 37.0313 34.8925 37.0614 34.7704 37.0614H34.7686Z"
      fill="#3D3C42"
    />
    <path
      d="M55.3806 35.4857C54.6392 35.4857 53.8151 35.3428 53.145 35.2281L53.0455 35.2112C52.3811 35.0965 51.8855 34.946 51.5645 34.7599H41.5034C39.8085 34.7599 38.3143 33.7445 37.6874 32.1688L34.1528 25.1664C33.9802 24.8261 34.1172 24.4105 34.4569 24.2375C34.7967 24.0664 35.2115 24.2018 35.3842 24.5422L38.9319 31.5727C38.9412 31.5934 38.9506 31.6141 38.9581 31.6348C39.3692 32.6934 40.3678 33.3778 41.5016 33.3778H51.7823C51.9587 33.3778 52.1276 33.4455 52.2534 33.564C52.3116 33.596 52.5763 33.7276 53.2783 33.8479L53.3797 33.8649C55.039 34.1507 55.9738 34.1845 56.1596 33.9608C56.2253 33.5696 55.3449 33.3666 55.3356 33.3647C54.9601 33.2951 54.7142 32.9341 54.7837 32.5599C54.8531 32.1838 55.2117 31.9338 55.5871 32.0071C56.6645 32.2064 57.7288 32.9736 57.5205 34.1845C57.421 34.7599 56.9911 35.45 55.4989 35.4838C55.4594 35.4838 55.42 35.4838 55.3787 35.4838L55.3806 35.4857Z"
      fill="#3D3C42"
    />
    <path
      d="M52.1152 32.1218C52.0871 32.1218 52.0589 32.1218 52.0307 32.1161L43.1503 31.0312C41.8833 30.8356 40.8509 29.9801 40.4323 28.7917C40.0982 28.049 39.7322 27.1991 39.3568 26.3247C38.3751 24.0401 37.262 21.449 36.5036 20.4054C34.8931 18.1941 31.9987 18.1283 31.9686 18.1283C31.5876 18.1227 31.2835 17.8087 31.2891 17.427C31.2948 17.049 31.6026 16.7463 31.9799 16.7463C32.1357 16.7482 35.6064 16.8271 37.6205 19.5912C38.4708 20.7589 39.567 23.3105 40.6276 25.7794C41.0048 26.6575 41.369 27.5074 41.705 28.2502C41.7144 28.2709 41.7219 28.2915 41.7294 28.3122C41.9753 29.0305 42.5928 29.5476 43.3418 29.6642L52.0589 30.7285L53.889 30.1813C54.2532 30.0722 54.638 30.2809 54.7469 30.6457C54.8557 31.0105 54.6474 31.396 54.2832 31.505L52.3161 32.0917C52.2522 32.1105 52.1847 32.1199 52.119 32.1199L52.1152 32.1218Z"
      fill="#3D3C42"
    />
    <path
      d="M5.43233 32.1218C5.36664 32.1218 5.29906 32.1124 5.23524 32.0936L3.26807 31.5069C2.90205 31.3978 2.69557 31.0124 2.80444 30.6476C2.91331 30.2809 3.29623 30.0741 3.66226 30.1831L5.4924 30.7303L14.2301 29.6623C14.9585 29.5495 15.576 29.0324 15.8219 28.3141C15.8294 28.2934 15.8369 28.2727 15.8463 28.2539C16.1823 27.5093 16.5483 26.6556 16.9256 25.7775C17.9843 23.3105 19.0786 20.7608 19.9308 19.595C21.44 17.5285 23.3489 17.0772 24.2687 16.861L24.5972 16.782C25.6803 16.5188 26.2302 16.2705 26.2302 15.6444V14.723C26.2302 14.3413 26.54 14.0311 26.9191 14.0311C27.2983 14.0311 27.6099 14.3413 27.6099 14.723V15.6444C27.6099 17.4721 25.7948 17.9121 24.9238 18.1246L24.5859 18.2054C23.7131 18.4122 22.2527 18.7564 21.0458 20.411C20.2856 21.4509 19.1743 24.0401 18.1945 26.3228C17.8191 27.1991 17.4531 28.0508 17.1171 28.7955C16.6966 29.9857 15.6661 30.8413 14.4197 31.0331L5.51868 32.1199C5.49052 32.1237 5.46237 32.1255 5.43421 32.1255L5.43233 32.1218Z"
      fill="#3D3C42"
    />
    <path
      d="M32.9199 18.3671C32.843 18.3671 32.7622 18.354 32.6853 18.3258C31.5797 17.9271 29.9072 17.3217 29.9072 15.415V15.18C29.9072 14.7982 30.2169 14.4899 30.598 14.4899C30.979 14.4899 31.2869 14.8001 31.2869 15.18V15.415C31.2869 16.2104 31.7768 16.5282 33.1527 17.0264C33.5112 17.1562 33.697 17.5511 33.5675 17.9102C33.4661 18.1922 33.2015 18.3671 32.918 18.3671H32.9199Z"
      fill="#3D3C42"
    />
    <path
      d="M28.734 15.9678C26.3651 15.9678 23.4707 13.2658 23.4707 10.1124V8.26971C23.4707 7.97638 23.6547 7.71501 23.9306 7.61911C23.9587 7.60971 26.7987 6.58493 28.199 4.64066C28.3248 4.46579 28.5256 4.36049 28.7415 4.35485C28.9555 4.34921 29.162 4.44511 29.2971 4.61434C30.7162 6.38561 33.5975 7.62287 33.6256 7.63415C33.8809 7.74321 34.0461 7.9933 34.0461 8.27159V10.1143C34.0461 13.2131 31.0747 15.9697 28.7321 15.9697L28.734 15.9678ZM24.8503 8.73979V10.1124C24.8503 12.3745 27.0747 14.5858 28.734 14.5858C30.3933 14.5858 32.6683 12.3745 32.6683 10.1124V8.71347C31.8649 8.32988 30.0892 7.39911 28.7847 6.0998C27.4782 7.51381 25.6875 8.38441 24.8503 8.73791V8.73979Z"
      fill="#3D3C42"
    />
    <path
      d="M28.7583 21.3813C26.9075 21.3813 25.2576 20.097 24.6513 18.1866C24.5368 17.8218 24.7377 17.4345 25.0999 17.3179C25.4641 17.2032 25.8508 17.4025 25.9671 17.7673C26.3895 19.1023 27.512 19.9992 28.7583 19.9992C30.0047 19.9992 31.1272 19.1023 31.5495 17.7673C31.6659 17.4044 32.0526 17.2032 32.4167 17.3179C32.779 17.4326 32.9817 17.8218 32.8654 18.1866C32.2591 20.097 30.6091 21.3813 28.7583 21.3813Z"
      fill="#3D3C42"
    />
    <path
      d="M37.481 17.5304C37.4529 17.5304 37.4266 17.5304 37.3984 17.5247C37.0212 17.4796 36.7509 17.1355 36.7959 16.7557C36.9029 15.8606 36.2365 14.9242 35.2455 14.5764C35.0164 14.4955 34.8456 14.2999 34.7968 14.0611C34.748 13.8223 34.8287 13.576 35.0089 13.4124C35.4688 12.9931 35.6847 12.3989 35.5721 11.863L35.4857 11.4418C35.3825 10.936 35.253 10.3042 35.1385 9.8868C34.8851 8.95415 34.9752 8.37877 35.0634 7.82031C35.1347 7.36339 35.2042 6.93279 35.1047 6.19194C35.0371 5.68801 34.6992 3.95622 33.1976 2.84118C32.0957 2.02324 30.6373 1.75435 28.8691 2.04016C28.7958 2.05144 28.7208 2.05144 28.6476 2.04016C26.8775 1.75247 25.4209 2.02136 24.319 2.84118C22.8174 3.95622 22.4795 5.68801 22.4119 6.19194C22.3125 6.93279 22.38 7.36527 22.4532 7.82031C22.5415 8.37877 22.6334 8.95603 22.3782 9.8868C22.2637 10.3061 22.1341 10.936 22.0309 11.4418L21.9445 11.863C21.8319 12.3989 22.0478 12.9931 22.5077 13.4124C22.6879 13.576 22.7686 13.8223 22.7198 14.0611C22.671 14.2999 22.5002 14.4955 22.2712 14.5764C21.2801 14.9242 20.6137 15.8606 20.7207 16.7557C20.7658 17.1355 20.4973 17.4796 20.1182 17.5247C19.7371 17.568 19.3955 17.301 19.3504 16.9211C19.2003 15.6651 19.8535 14.4165 20.9685 13.6888C20.5856 13.0458 20.4429 12.2917 20.5931 11.5791L20.6775 11.1654C20.7845 10.6389 20.9197 9.98458 21.0454 9.52201C21.2219 8.87706 21.1656 8.52356 21.0886 8.03467C21.0116 7.54954 20.9159 6.94595 21.0436 6.00579C21.2726 4.29844 22.1904 2.70016 23.495 1.7299C24.884 0.69948 26.6522 0.338455 28.7564 0.658113C30.8606 0.338455 32.6288 0.69948 34.0178 1.7299C35.3243 2.70016 36.2403 4.29844 36.4693 6.00579C36.5969 6.94783 36.5012 7.55142 36.4242 8.03467C36.3473 8.52356 36.291 8.87706 36.4674 9.52201C36.5932 9.9827 36.7265 10.6389 36.8353 11.1654L36.9198 11.5791C37.07 12.2917 36.9273 13.0458 36.5444 13.6888C37.6594 14.4165 38.3126 15.6651 38.1624 16.9211C38.1211 17.2709 37.8227 17.5285 37.4792 17.5285L37.481 17.5304Z"
      fill="#3D3C42"
    />
    <path
      d="M34.2772 37.0614H25.9994C25.6183 37.0614 25.3086 36.7511 25.3086 36.3713C25.3086 35.9915 25.6183 35.6794 25.9994 35.6794H34.2772C34.6583 35.6794 34.9661 35.9896 34.9661 36.3713C34.9661 36.753 34.6564 37.0614 34.2772 37.0614Z"
      fill="#3D3C42"
    />
    <path
      d="M28.7741 12.6453H28.2992C27.9181 12.6453 27.6084 12.335 27.6084 11.9533C27.6084 11.5716 27.9181 11.2613 28.2992 11.2613H28.53V9.35844C28.53 8.63075 29.1213 8.03656 29.8496 8.03656H30.1406C30.5216 8.03656 30.8313 8.34681 30.8313 8.72852C30.8313 9.11023 30.5216 9.41861 30.1406 9.41861H29.9116V11.5058C29.9116 12.1338 29.4029 12.6434 28.7759 12.6434L28.7741 12.6453Z"
      fill="#3D3C42"
    />
    <path
      d="M21.4002 27.3871C21.2688 27.3871 21.1355 27.3495 21.0172 27.2705L18.2579 25.4278C17.9407 25.2153 17.8544 24.7866 18.0665 24.4688C18.2786 24.151 18.7066 24.0645 19.0238 24.277L21.7831 26.1197C22.1003 26.3322 22.1866 26.7609 21.9745 27.0787C21.8413 27.278 21.6235 27.3871 21.4002 27.3871Z"
      fill="#3D3C42"
    />
    <path
      d="M36.1165 27.3871C35.8931 27.3871 35.6753 27.2799 35.5421 27.0787C35.33 26.7609 35.4163 26.3322 35.7335 26.1197L38.4928 24.277C38.81 24.0645 39.238 24.151 39.4501 24.4688C39.6622 24.7866 39.5759 25.2153 39.2587 25.4278L36.4994 27.2705C36.3811 27.3495 36.2478 27.3871 36.1165 27.3871Z"
      fill="#3D3C42"
    />
    <path
      d="M53.1752 20.8962C52.9237 20.8962 52.6909 20.7589 52.5689 20.537L51.5628 18.6924C51.0973 17.8406 50.4009 17.143 49.5506 16.6767L47.7111 15.6688C47.4896 15.5466 47.3525 15.3153 47.3525 15.0615C47.3525 14.8077 47.4896 14.5764 47.7111 14.4542L49.5506 13.4463C50.4009 12.98 51.0973 12.2842 51.5628 11.4306L52.5689 9.58784C52.6909 9.36596 52.9218 9.2287 53.1752 9.2287C53.4286 9.2287 53.6595 9.36596 53.7815 9.58784L54.7876 11.4306C55.2531 12.2824 55.9476 12.98 56.7998 13.4463L58.6393 14.4542C58.8608 14.5764 58.9979 14.8077 58.9979 15.0615C58.9979 15.3153 58.8608 15.5466 58.6393 15.6688L56.7998 16.6767C55.9476 17.143 55.2512 17.8406 54.7876 18.6924L53.7815 20.537C53.6595 20.7589 53.4286 20.8962 53.1752 20.8962ZM49.4793 15.0615L50.2132 15.4639C51.2963 16.0581 52.1822 16.9456 52.7735 18.0305L53.1752 18.7658L53.5769 18.0305C54.1682 16.9456 55.0541 16.06 56.1372 15.4658L56.8712 15.0634L56.1372 14.661C55.0541 14.0668 54.1682 13.1793 53.5769 12.0962L53.1752 11.361L52.7735 12.0962C52.1804 13.1812 51.2944 14.0687 50.2132 14.661L49.4793 15.0634V15.0615Z"
      fill="#A4F0C1"
    />
    <path
      d="M44.2971 7.81091C44.0456 7.81091 43.8128 7.67365 43.6908 7.45177L43.062 6.29912C42.7955 5.81024 42.3956 5.40972 41.9076 5.14272L40.757 4.5128C40.5355 4.39058 40.3984 4.1593 40.3984 3.90546C40.3984 3.65161 40.5355 3.42033 40.757 3.29811L41.9076 2.6682C42.3956 2.40119 42.7955 2.00068 43.0639 1.51179L43.6927 0.359144C43.8147 0.137264 44.0456 0 44.299 0C44.5524 0 44.7833 0.137264 44.9053 0.359144L45.5341 1.51179C45.8006 2.00068 46.2005 2.40119 46.6885 2.6682L47.8391 3.29811C48.0606 3.42033 48.1977 3.65161 48.1977 3.90546C48.1977 4.1593 48.0606 4.39058 47.8391 4.5128L46.6885 5.14272C46.2005 5.40972 45.8006 5.81024 45.5322 6.29912L44.9034 7.45177C44.7814 7.67365 44.5505 7.81091 44.2971 7.81091ZM42.5252 3.90546L42.5683 3.9299C43.2891 4.32477 43.8766 4.9152 44.2727 5.63725L44.2971 5.68049L44.3215 5.63725C44.7157 4.9152 45.3051 4.32477 46.0259 3.9299L46.0691 3.90546L46.0259 3.88101C45.3051 3.48614 44.7176 2.8976 44.3215 2.17555L44.2971 2.1323L44.2727 2.17555C43.8785 2.8976 43.2891 3.48802 42.5683 3.88101L42.5252 3.90546Z"
      fill="#A4F0C1"
    />
  </svg>
)

export default Topic20009400Icon
