import React, { useCallback, useState } from "react"
import { useTranslations } from "next-intl"

import clsx from "clsx"
import { useAuthentication } from "../../../hooks/authentication"
import {
  AdviqoButton,
  AdviqoLink,
  Box,
  Checkbox,
  FormControlLabel,
} from "../../_common"
import Input from "../components/Input"
import Message from "../components/Message/Message"
import useForm from "../hooks/useForm"
import {
  AuthModuleForms,
  IAuthModuleBodyProps,
  IMessage,
  TUseFormValues,
} from "../types"
import useTrackClientError from "../hooks/useTrackClientError"
import { getRedirectUrl } from "../utils/redirects"
import classes from "./RegistrationForm.module.scss"

type IRegistrationFormProps = Pick<
  IAuthModuleBodyProps,
  "successRegisterRedirectPage" | "standalone"
>

const RegistrationForm: React.FC<IRegistrationFormProps> = ({
  successRegisterRedirectPage,
  standalone,
}) => {
  const { authenticate } = useAuthentication()
  const translate = useTranslations()
  const [optinCheck, setOptinCheck] = useState(false)

  const handleFormSubmit = useCallback(
    async (values: TUseFormValues): Promise<IMessage | void> => {
      const { username, password } = values
      if (username && password) {
        const errorData = await authenticate(
          {
            userName: username.value,
            password: password.value,
            optin: optinCheck,
          },
          AuthModuleForms.REGISTRATION,
          getRedirectUrl(successRegisterRedirectPage, standalone)
        )

        if (errorData !== undefined) {
          return {
            type: "error",
            summary: errorData.message,
          }
        }
      }
    },
    [authenticate, standalone, successRegisterRedirectPage, optinCheck]
  )

  const {
    register,
    errors,
    isDisabled,
    message,
    submitHandler,
    values,
  } = useForm(AuthModuleForms.REGISTRATION, handleFormSubmit)

  useTrackClientError(values, errors, AuthModuleForms.REGISTRATION)

  return (
    <Box className={classes.root}>
      <form noValidate data-testid="registration-form">
        {message && <Message message={message} />}
        <Box className={classes.formField}>
          <Input
            {...register({ name: "username", required: true })}
            type="email"
            label="E-Mail-Adresse"
            placeholder="E-Mail-Adresse"
            error={errors.username}
            dataTestId="username"
          />
        </Box>
        <Box className={classes.formField}>
          <Input
            {...register({ name: "password", required: true })}
            type="password"
            label="Passwort (mindestens 6 Zeichen)"
            placeholder="Passwort (mindestens 6 Zeichen)"
            maxLength={30}
            withVisibilityControl
            withIndicator
            error={errors.password}
            dataTestId="password"
          />
        </Box>
        <Box className={classes.formField}>
          <FormControlLabel
            value={optinCheck}
            classes={{
              root: classes.newsletterOptionLabel,
              label: classes.newsletterOptionText,
            }}
            onChange={() => setOptinCheck(!optinCheck)}
            control={
              <Checkbox
                className={classes.newsletterOptionIcon}
                color="primary"
                data-testid="opt-in-checkbox"
              />
            }
            label={translate("registration.newsletterOptionLabel")}
            labelPlacement="end"
          />
        </Box>
        <Box className={classes.formField}>
          <AdviqoButton
            fullWidth
            color="secondary"
            type="submit"
            label="Registrieren"
            labelCentered
            onClick={submitHandler}
            testId="register-form-submit-button"
            disabled={isDisabled}
          />
        </Box>
        <Box className={clsx(classes.formField, classes.formLink)}>
          Es gelten unsere
          <AdviqoLink
            to="/agb"
            target="_blank"
            className={classes.link}
            data-testid="agb-external-link"
          >
            AGB.
          </AdviqoLink>
          Hinweise zum Datenschutz finden Sie in der
          <AdviqoLink
            to="/datenschutz"
            target="_blank"
            className={classes.link}
            data-testid="datenschutz-external-link"
          >
            Datenschutzerklärung.
          </AdviqoLink>
        </Box>
      </form>
    </Box>
  )
}

export default RegistrationForm
