import { QUERY_PAYLOAD, QUERY_TOKEN_VERIFICATION } from "../consts"
import { PageSlug } from "../../../types"
import {
  AuthModuleForms,
  TAuthModuleDefaultForm,
  TAuthModuleForm,
} from "../types"
import { ReadonlyURLSearchParams } from "next/navigation"

export function cleanupAuthParams(
  params: ReadonlyURLSearchParams,
  cleanupList: string[] = []
): URLSearchParams {
  const cleanupParamsList = [
    QUERY_TOKEN_VERIFICATION,
    QUERY_PAYLOAD,
    ...cleanupList,
  ]
  const newParams = new URLSearchParams(params)
  cleanupParamsList.forEach((key) => newParams.delete(key))
  return newParams
}

export function hasAuthParams(query: URLSearchParams): boolean {
  return [QUERY_TOKEN_VERIFICATION, QUERY_PAYLOAD].some((param) =>
    query.has(param)
  )
}

export function getFormNameByPath(
  path: string | null | undefined,
  defaultForm: TAuthModuleDefaultForm
): TAuthModuleForm {
  if (path?.includes(`/${PageSlug.LOGIN}`)) {
    return AuthModuleForms.LOGIN
  }
  if (path?.includes(`/${PageSlug.REGISTRATION}`)) {
    return AuthModuleForms.REGISTRATION
  }
  if (path?.includes(`/${PageSlug.PASSWORD_RECOVERY}`)) {
    return AuthModuleForms.FORGET_PASSWORD_EMAIL
  }
  if (path?.includes(`/${PageSlug.RESET_PASSWORD}`)) {
    return AuthModuleForms.FORGET_PASSWORD_PASSWORD
  }
  return defaultForm
}
