import clsx from "clsx"
import React, { ComponentProps, useEffect, useState } from "react"

import AuthModuleComponent from "../../../components/AuthModule"
import { useAuthentication } from "../../../hooks/authentication"
import { usePrevious } from "../../../hooks/usePrevious"
import { useUser } from "../../../hooks/user"
import { ClientTrackingEventName } from "../../../types"
import usePhoneNumber from "../../PhoneNumber/usePhoneNumber"
import { Box, CircularProgress, Typography } from "../../_common"
import { PhoneNumber } from "../components/PhoneNumber"
import SearchColoredIcon from "../../_common/QuesticoIcon/SearchColoredIcon"
import { IUseWizardTracking } from "../types"
import { Screen } from "./Screen"
import classes from "./Auth.module.scss"

type IAuthScreen = Pick<IUseWizardTracking, "trackWizardStep"> & {
  authScreenAuthModule: ComponentProps<typeof AuthModuleComponent>["data"]
  authScreenText: string
  isLastStep: boolean
}

type IconSize = "small" | "medium" | "big"

const SearchHeader = ({
  text,
  iconSize,
}: {
  text: string
  iconSize: IconSize
}) => {
  const iconClasses = clsx(classes.icon, {
    [classes.iconSmall]: iconSize === "small",
    [classes.iconMedium]: iconSize === "medium",
    [classes.iconBig]: iconSize === "big",
  })

  return (
    <>
      <Box className={iconClasses} data-testid="magnifier-icon">
        <SearchColoredIcon />
      </Box>

      <Typography
        component="p"
        className={classes.text}
        data-testid="text-under-magnifier-icon"
      >
        {text}
      </Typography>
    </>
  )
}

export const Auth: React.FC<IAuthScreen> = ({
  authScreenAuthModule,
  authScreenText,
  trackWizardStep,
  isLastStep,
}) => {
  const [isPhoneSection, setIsPhoneSection] = useState(false)

  const {
    state: { initialLoadReady },
  } = useUser()

  const { currentPhoneNumber } = usePhoneNumber(false)

  const { isAuthenticated } = useAuthentication()

  const prevPhoneNumber = usePrevious(currentPhoneNumber)

  useEffect(() => {
    if (
      isAuthenticated &&
      initialLoadReady &&
      isLastStep &&
      !currentPhoneNumber
    ) {
      setIsPhoneSection(true)

      trackWizardStep({
        eventName: ClientTrackingEventName.WIZARD_STEP_VIEWED,
        step: "phoneNumber",
      })
    }
  }, [
    initialLoadReady,
    isAuthenticated,
    isLastStep,
    currentPhoneNumber,
    trackWizardStep,
  ])

  useEffect(() => {
    if (
      isAuthenticated &&
      initialLoadReady &&
      isLastStep &&
      prevPhoneNumber !== currentPhoneNumber &&
      currentPhoneNumber &&
      isPhoneSection
    ) {
      trackWizardStep({
        eventName: ClientTrackingEventName.WIZARD_STEP_COMPLETED,
        step: "phoneNumber",
      })
    }
  }, [
    initialLoadReady,
    isAuthenticated,
    isLastStep,
    prevPhoneNumber,
    isPhoneSection,
    currentPhoneNumber,
    trackWizardStep,
  ])

  if (!isAuthenticated) {
    return (
      <Screen rootClassName={classes.root}>
        <AuthModuleComponent data={authScreenAuthModule} />
      </Screen>
    )
  }

  if (!initialLoadReady) {
    return (
      <Screen rootClassName={clsx(classes.root, classes.centered)}>
        <CircularProgress size={50} />
      </Screen>
    )
  }

  if (!currentPhoneNumber) {
    return (
      <Screen rootClassName={clsx(classes.root, classes.withPadding)}>
        <SearchHeader text={authScreenText as string} iconSize="small" />

        <PhoneNumber />
      </Screen>
    )
  }

  return (
    <Screen rootClassName={clsx(classes.root, classes.centered)}>
      <SearchHeader
        text="Wir suchen jetzt den perfekten Berater für dich."
        iconSize="big"
      />
    </Screen>
  )
}
