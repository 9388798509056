import React from "react"

import LoginForm from "./forms/LoginForm"
import RegistrationForm from "./forms/RegistrationForm"
import ForgetPasswordEmailForm from "./forms/ForgetPasswordEmailForm"
import ForgetPasswordPasswordForm from "./forms/ForgetPasswordPasswordForm"
import { AuthModuleForms, IAuthModuleBodyProps } from "./types"

type IAuthFormProps = Omit<
  IAuthModuleBodyProps,
  | "authForm"
  | "expertEntry"
  | "loginFormTitle"
  | "loginTitle"
  | "registrationTitle"
  | "registrationFooterText"
>

const AuthModuleForm: React.FC<IAuthFormProps> = ({
  activeForm,
  redirectToAuthForm,
  successLoginRedirectPage,
  successRegisterRedirectPage,
  loginFormPlaceholder,
  standalone,
}) => {
  switch (activeForm) {
    case AuthModuleForms.LOGIN:
      return (
        <LoginForm
          loginFormPlaceholder={loginFormPlaceholder}
          redirectToAuthForm={redirectToAuthForm}
          successLoginRedirectPage={successLoginRedirectPage}
          standalone={standalone}
        />
      )
    case AuthModuleForms.REGISTRATION:
      return (
        <RegistrationForm
          successRegisterRedirectPage={successRegisterRedirectPage}
          standalone={standalone}
        />
      )
    case AuthModuleForms.FORGET_PASSWORD_EMAIL:
      return <ForgetPasswordEmailForm redirectToAuthForm={redirectToAuthForm} />
    case AuthModuleForms.FORGET_PASSWORD_PASSWORD:
      return (
        <ForgetPasswordPasswordForm redirectToAuthForm={redirectToAuthForm} />
      )
    default:
      throw new Error("Wrong auth module form")
  }
}

export default AuthModuleForm
