import React from "react"
import { IIconProps } from "../../_common/QuesticoIcon/types"

const Method20000408Icon: React.FC<IIconProps> = ({
  height = "100%",
  width = "100%",
}) => (
  <svg width={width} height={height} viewBox="0 0 50 48">
    <ellipse
      cx="26.6953"
      cy="14.5136"
      rx="9.0654"
      ry="8.61213"
      fill="#87E5DB"
    />
    <path
      d="M11.0638 41.1017V47.6023H14.8229C14.8229 47.6023 15.2652 43.1912 14.1596 39.7088C13.0539 36.2263 5.9779 32.2796 5.09339 30.1901C4.20889 28.1006 4.43001 9.52762 4.43001 9.52762H2.21875V31.1187L11.0638 41.1017Z"
      fill="#87E5DB"
    />
    <path
      d="M39.6071 41.1017V47.6023H35.8479C35.8479 47.6023 35.4057 43.1912 36.5113 39.7088C37.617 36.2263 44.693 32.2796 45.5775 30.1901C46.462 28.1006 46.2409 9.52762 46.2409 9.52762H48.4521V31.1187L39.6071 41.1017Z"
      fill="#87E5DB"
    />
    <path
      d="M29.0385 26.1653L27.5913 25.9176C28.0973 22.951 29.4033 21.4943 30.5563 20.2086C31.727 18.9052 32.6506 17.873 32.6506 15.4373C32.6506 11.3737 29.3503 8.06503 25.297 8.06503C21.2437 8.06503 17.9434 11.3737 17.9434 15.4373C17.9434 17.8671 18.867 18.8993 20.0377 20.2086C21.1907 21.4943 22.4909 22.951 23.0027 25.9176L21.5555 26.1653C21.1201 23.6175 20.0141 22.3848 18.9435 21.1876C17.7316 19.8311 16.4785 18.4333 16.4785 15.4314C16.4785 10.5539 20.4377 6.58469 25.3029 6.58469C30.168 6.58469 34.1272 10.5539 34.1272 15.4314C34.1272 18.4274 32.8742 19.8311 31.6623 21.1876C30.5916 22.3789 29.4915 23.6175 29.0503 26.1653H29.0385Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
    <path
      d="M26.0264 19.4478H24.5557V26.0415H26.0264V19.4478Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
    <path
      d="M26.0264 0.822754H24.5557V3.31752H26.0264V0.822754Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
    <path
      d="M18.6356 2.40476L17.3652 3.14777L18.6103 5.28733L19.8807 4.54432L18.6356 2.40476Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
    <path
      d="M13.0407 7.4886L12.2998 8.76228L14.4342 10.0102L15.1751 8.73649L13.0407 7.4886Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
    <path
      d="M13.1131 14.7002H10.7129V16.1746H13.1131V14.7002Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
    <path
      d="M31.8525 2.41073L30.6074 4.55029L31.8778 5.2933L33.1229 3.15374L31.8525 2.41073Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
    <path
      d="M37.4508 7.49072L35.3164 8.7386L36.0573 10.0123L38.1917 8.7644L37.4508 7.49072Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
    <path
      d="M39.7801 14.7002H37.3799V16.1746H39.7801V14.7002Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
    <path
      d="M28.3146 30.3825H22.1787V31.8569H28.3146V30.3825Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
    <path
      d="M21.9905 19.613L20.9492 18.5691L25.2967 14.2106L29.55 18.575L28.497 19.6012L25.2849 16.3043L21.9905 19.613Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
    <path
      d="M30.0264 47.0729H28.5557V39.7655C28.5557 36.5866 29.8381 33.5375 32.0736 31.3907L36.4329 27.1089L39.9332 20.6095C40.4686 19.6659 41.4569 19.0643 42.4452 19.0643C43.9983 19.0643 45.3161 20.3795 45.3161 21.9424C45.3161 22.4438 45.1867 22.9274 44.9455 23.2989L39.892 32.6292L38.5978 31.9274L43.6983 22.5145C43.7865 22.3789 43.8395 22.1666 43.8395 21.9424C43.8395 21.1934 43.1865 20.5388 42.4393 20.5388C41.904 20.5388 41.4275 20.9457 41.2098 21.3232L37.6095 27.9995L33.0855 32.4464C31.1324 34.3219 30.0146 36.9877 30.0146 39.7596L30.0264 46.8558V47.0729Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
    <path
      d="M39.8621 47.0874L38.6914 47.073V42.5258C38.6914 41.423 39.1267 40.3614 39.8856 39.6005L46.4627 33.0068C47.4569 32.0101 48.0276 30.6713 48.0276 29.3384V10.3829H47.8746C46.4157 10.3829 45.2332 11.5684 45.2332 13.031V21.4943H43.7625V13.031C43.7625 10.7604 45.6097 8.90848 47.8746 8.90848H49.4983V29.3384C49.4983 31.0606 48.7747 32.7768 47.504 34.0507L40.9269 40.6444C40.4504 41.1222 40.1621 41.8299 40.1621 42.5317V47.0789L39.8621 47.0874Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
    <path
      d="M22.0251 47.073H20.5544V39.7656C20.5544 36.9878 19.4366 34.3279 17.4894 32.4583L12.9595 28.0055L9.35329 21.3115C9.14739 20.9458 8.67088 20.5389 8.13553 20.5389C7.3884 20.5389 6.7354 21.1935 6.7354 21.9425C6.7354 22.1666 6.78835 22.379 6.87659 22.5146L6.91189 22.5736L11.9771 31.9334L10.6828 32.6352L5.62941 23.3049C5.38821 22.9334 5.25879 22.4497 5.25879 21.9484C5.25879 20.3914 6.57068 19.0703 8.12965 19.0703C9.12386 19.0703 10.1063 19.6719 10.6358 20.5978L14.1479 27.109L18.513 31.3967C20.7426 33.5376 22.0251 36.5867 22.0251 39.7656V47.073Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
    <path
      d="M11.8069 47.0731H10.3362V42.5259C10.3362 41.8241 10.0538 41.1223 9.5714 40.6387L2.99431 34.0449C1.73536 32.8831 1 31.155 1 29.3326V8.90858H2.62368C4.89449 8.90858 6.73584 10.7605 6.73584 13.0311V21.4945H5.26511V13.0311C5.26511 11.5685 4.07676 10.383 2.62368 10.383H2.47073V29.3385C2.47073 30.7717 3.01784 32.0633 4.01205 32.9833L10.6127 39.6006C11.3716 40.3615 11.8069 41.429 11.8069 42.5259V47.0731Z"
      fill="#3D3C42"
      stroke="#3D3C42"
      strokeWidth="0.3"
    />
  </svg>
)

export default Method20000408Icon
