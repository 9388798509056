import { AuthModuleForms, TAuthModuleForm } from "../types"

export function isLoginOrRegistration(form: TAuthModuleForm): boolean {
  return form === AuthModuleForms.LOGIN || form === AuthModuleForms.REGISTRATION
}

export function getFormTitleText(
  form: TAuthModuleForm,
  loginFormTitle: string
): string {
  switch (form) {
    case AuthModuleForms.LOGIN:
      return loginFormTitle || "E-Mail Login"
    case AuthModuleForms.REGISTRATION:
      return "Jetzt registrieren!"
    default:
      return ""
  }
}
