import { useCallback, useState } from "react"
import { IUseSwipable } from "../types"

export const useSwipable = (
  stepsAmount: number,
  initialStepIdx = 0
): IUseSwipable => {
  const [activeStepIdx, setactiveStepIdx] = useState(initialStepIdx)

  const handleNext = useCallback(() => {
    setactiveStepIdx((prevactiveStepIdx) => prevactiveStepIdx + 1)
  }, [])

  const handleBack = useCallback(() => {
    setactiveStepIdx((prevactiveStepIdx) => prevactiveStepIdx - 1)
  }, [])

  const handleStepChange = useCallback((step: number) => {
    setactiveStepIdx(step)
  }, [])

  return {
    activeStepIdx,
    stepsAmount,
    handleNext,
    handleBack,
    handleStepChange,
    isLastStep: activeStepIdx === stepsAmount - 1,
    isForward: (step: number) => step > activeStepIdx,
  }
}
