import { LOCAL_STORAGE_KEYS } from "../../../types"
import { storage } from "../../../utils/localStorage"

export const getRedirectUrl = (
  successRedirectPage: string | undefined,
  standalone: boolean
): string | false => {
  const redirectUrl =
    successRedirectPage ||
    (!standalone &&
      (storage.getItem(LOCAL_STORAGE_KEYS.AUTH_SUCCESS_REDIRECT_URL) || "/"))

  return redirectUrl
}
