import React from "react"
import { IIconProps } from "../../_common/QuesticoIcon/types"

const Topic20009300Icon: React.FC<IIconProps> = ({
  height = "100%",
  width = "100%",
}) => (
  <svg width={width} height={height} viewBox="0 0 50 52">
    <path
      d="M32.6581 31.747H9.65189C8.33317 31.747 7.26562 32.801 7.26562 34.1029V45.8466C7.26562 47.1486 8.33317 48.2026 9.65189 48.2026H32.6581C33.9768 48.2026 35.0444 47.1486 35.0444 45.8466V34.1029C35.0444 32.801 33.9768 31.747 32.6581 31.747ZM21.1594 41.746C20.2593 41.746 19.5284 41.0245 19.5284 40.1358C19.5284 39.2471 20.2593 38.5255 21.1594 38.5255C22.0594 38.5255 22.7903 39.2471 22.7903 40.1358C22.7903 41.0245 22.0594 41.746 21.1594 41.746Z"
      fill="#00B4CB"
    />
    <path
      d="M5.12793 47.7806V49.272C5.12793 50.3123 5.98266 51.1561 7.03625 51.1561H34.8446C35.8982 51.1561 36.753 50.3123 36.753 49.272V47.7806H5.12793Z"
      fill="#00B4CB"
    />
    <path
      d="M30.5177 28.8244C30.3031 28.8244 30.1008 28.7194 29.9822 28.5385C29.8461 28.3336 29.8426 28.0684 29.9717 27.86C30.2927 27.345 30.4985 26.7888 30.584 26.2084C30.6328 25.8829 30.6415 25.5522 30.6119 25.2216C28.9914 23.9936 28.1035 22.3696 28.1035 20.6216C28.1035 17.0256 31.969 14.1013 36.7223 14.1013C41.4757 14.1013 45.3412 17.0274 45.3412 20.6216C45.3412 24.2158 41.4757 27.1418 36.7223 27.1418C35.9653 27.1418 35.21 27.066 34.4739 26.9162C33.9872 27.4001 33.4273 27.8049 32.8063 28.1217C32.1208 28.4731 31.3811 28.7073 30.6084 28.8175C30.5787 28.8227 30.5473 28.8244 30.5177 28.8244ZM36.7223 15.3654C32.6772 15.3654 29.3856 17.7231 29.3856 20.6216C29.3856 22.0303 30.1688 23.3582 31.5887 24.3605C31.73 24.4604 31.8242 24.6119 31.8504 24.7824C31.9306 25.318 31.9324 25.8588 31.8539 26.3909C31.8103 26.6837 31.744 26.9696 31.655 27.2486C31.8469 27.1763 32.0335 27.0919 32.2167 26.9989C32.8133 26.6923 33.3383 26.2893 33.7779 25.8002C33.9349 25.6246 34.1773 25.5505 34.4093 25.6056C35.1629 25.7865 35.9409 25.8777 36.7223 25.8777C40.7675 25.8777 44.0591 23.52 44.0591 20.6233C44.0591 17.7266 40.7675 15.3672 36.7223 15.3672V15.3654Z"
      fill="#3D3C42"
    />
    <path
      d="M47.5859 31.5851C47.5563 31.5851 47.5249 31.5834 47.4952 31.5782C46.7225 31.4697 45.9829 31.2355 45.2956 30.8824C44.6746 30.5638 44.1147 30.1591 43.628 29.6769C41.4807 30.1143 39.1503 29.8991 37.2245 29.1C36.8983 28.9639 36.7448 28.5936 36.8826 28.2716C37.0204 27.9495 37.3937 27.798 37.7216 27.934C39.5061 28.6746 41.7075 28.8434 43.6926 28.3663C43.9246 28.3112 44.1653 28.3852 44.324 28.5609C44.7636 29.05 45.2886 29.4547 45.8852 29.7596C46.0684 29.8526 46.255 29.9369 46.4469 30.0093C46.3579 29.7286 46.2916 29.4427 46.248 29.1499C46.1695 28.6195 46.1713 28.0787 46.2515 27.5414C46.2777 27.3709 46.3719 27.2193 46.5132 27.1194C47.9348 26.1171 48.7163 24.7893 48.7163 23.3805C48.7163 21.8151 47.7429 20.3409 46.0457 19.3334C45.7422 19.1526 45.6445 18.7651 45.8276 18.4654C46.0108 18.1657 46.4033 18.0693 46.7068 18.2501C48.7983 19.4918 49.9984 21.3621 49.9984 23.3823C49.9984 25.1303 49.1105 26.7543 47.49 27.9823C47.4603 28.3129 47.4691 28.6436 47.5179 28.9691C47.6034 29.5512 47.8092 30.1074 48.1302 30.6207C48.261 30.829 48.2558 31.0943 48.1197 31.2992C48.0011 31.4783 47.7988 31.5851 47.5842 31.5851H47.5859Z"
      fill="#A4F0C1"
    />
    <path
      d="M42.0964 52H0.64192C0.287817 52 0 51.7158 0 51.3662C0 51.0166 0.287817 50.7342 0.64192 50.7342H42.0964C42.4505 50.7342 42.7365 51.0183 42.7365 51.3662C42.7365 51.7141 42.4487 52 42.0964 52Z"
      fill="#3D3C42"
    />
    <path
      d="M32.8708 49.0464H9.01159C7.34225 49.0464 5.9834 47.7048 5.9834 46.0567V33.8911C5.9834 32.243 7.34225 30.9014 9.01159 30.9014H32.8708C34.5401 30.9014 35.899 32.243 35.899 33.8911V46.0567C35.899 47.7048 34.5401 49.0464 32.8708 49.0464ZM9.01159 32.1689C8.04871 32.1689 7.26549 32.9422 7.26549 33.8928V46.0584C7.26549 47.0091 8.04871 47.7823 9.01159 47.7823H32.8708C33.8337 47.7823 34.6169 47.0091 34.6169 46.0584V33.8928C34.6169 32.9422 33.8337 32.1689 32.8708 32.1689H9.01159Z"
      fill="#3D3C42"
    />
    <path
      d="M34.8309 52H7.05044C5.5189 52 4.27344 50.7703 4.27344 49.2583V47.9924C4.27344 47.6428 4.56126 47.3587 4.91536 47.3587H7.05218C7.40629 47.3587 7.69236 47.6428 7.69236 47.9924C7.69236 48.3421 7.40454 48.6262 7.05218 48.6262H5.55553V49.26C5.55553 50.0746 6.22536 50.7359 7.05044 50.7359H34.8309C35.656 50.7359 36.3258 50.0746 36.3258 49.26V48.6262H34.8309C34.4768 48.6262 34.189 48.3421 34.189 47.9924C34.189 47.6428 34.4768 47.3587 34.8309 47.3587H36.9678C37.3219 47.3587 37.6097 47.6428 37.6097 47.9924V49.2583C37.6097 50.7703 36.3642 52 34.8327 52H34.8309Z"
      fill="#3D3C42"
    />
    <path
      d="M33.6151 19.8139C33.1005 19.8139 32.6836 20.2255 32.6836 20.7335C32.6836 21.2416 33.1005 21.6532 33.6151 21.6532C34.1297 21.6532 34.5466 21.2416 34.5466 20.7335C34.5466 20.2255 34.1297 19.8139 33.6151 19.8139Z"
      fill="#00B4CB"
    />
    <path
      d="M36.8768 19.8139C36.3622 19.8139 35.9453 20.2255 35.9453 20.7335C35.9453 21.2416 36.3622 21.6532 36.8768 21.6532C37.3914 21.6532 37.8083 21.2416 37.8083 20.7335C37.8083 20.2255 37.3914 19.8139 36.8768 19.8139Z"
      fill="#00B4CB"
    />
    <path
      d="M40.1395 19.8139C39.6249 19.8139 39.208 20.2255 39.208 20.7335C39.208 21.2416 39.6249 21.6532 40.1395 21.6532C40.6541 21.6532 41.071 21.2416 41.071 20.7335C41.071 20.2255 40.6541 19.8139 40.1395 19.8139Z"
      fill="#00B4CB"
    />
    <path
      d="M23.1925 19.9327H22.0081C21.654 19.9327 21.3662 19.6485 21.3662 19.2989C21.3662 18.9493 21.654 18.6652 22.0081 18.6652H23.1925C24.2287 18.6652 24.7869 18.0779 24.7869 17.5251V14.6559C24.7869 14.3063 25.0747 14.0221 25.4271 14.0221C25.7794 14.0221 26.0672 14.3063 26.0672 14.6559V17.5251C26.0672 18.8959 24.8305 19.931 23.1908 19.931L23.1925 19.9327Z"
      fill="#3D3C42"
    />
    <path
      d="M21.2336 18.2449H19.7928C19.2465 18.2449 18.8037 18.6821 18.8037 19.2214V19.3782C18.8037 19.9175 19.2465 20.3546 19.7928 20.3546H21.2336C21.7798 20.3546 22.2226 19.9175 22.2226 19.3782V19.2214C22.2226 18.6821 21.7798 18.2449 21.2336 18.2449Z"
      fill="#3D3C42"
    />
    <path
      d="M9.82965 13.6036L11.7711 12.7598C11.7711 12.7598 12.0624 8.2941 15.122 7.26595C16.9309 6.65802 22.2477 8.12877 23.4914 8.26138C24.7351 8.39399 25.6422 8.19422 25.6422 8.19422V13.1817H26.9243C27.9883 11.4302 28.0476 8.87104 27.4231 7.19879L28.4994 3.71478C27.6447 3.58217 26.8126 3.62695 25.9108 2.98457C24.0548 1.66365 21.6546 0.258335 19.0206 0.39611C16.6187 0.521831 14.6842 2.55402 14.6842 2.55402C14.6842 2.55402 11.5688 3.33073 10.257 5.58853C8.94527 7.84633 9.82965 13.6054 9.82965 13.6054V13.6036Z"
      fill="#A4F0C1"
    />
    <path
      d="M11.5812 16.5951C10.4857 16.5951 9.56817 15.7839 9.44781 14.711L9.41293 14.3924C9.35362 13.8706 9.52457 13.3453 9.87867 12.9544C10.2345 12.5617 10.7421 12.3378 11.2759 12.3378H11.3247C11.6788 12.3378 11.9649 12.622 11.9649 12.9716C11.9649 13.3212 11.6771 13.6054 11.3247 13.6054H11.2759C11.1049 13.6054 10.948 13.6742 10.8346 13.8C10.7212 13.9257 10.6689 14.0876 10.688 14.2546L10.7229 14.5732C10.7718 15.0055 11.1398 15.331 11.5812 15.331C11.9353 15.331 12.2231 15.6152 12.2231 15.9648C12.2231 16.3144 11.9353 16.5985 11.5812 16.5985V16.5951Z"
      fill="#3D3C42"
    />
    <path
      d="M2.77864 46.5148C2.42454 46.5148 2.13672 46.2306 2.13672 45.881V35.9595C2.13672 30.8187 4.57532 27.8479 10.5567 25.7038C12.6987 24.9357 14.1029 24.2933 14.1029 22.4609V21.8306C14.1029 21.481 14.3908 21.1985 14.7449 21.1985C15.099 21.1985 15.385 21.4827 15.385 21.8306V22.4609C15.385 25.318 12.9499 26.1912 10.9945 26.8938C5.54343 28.8485 3.41881 31.3905 3.41881 35.9595V45.881C3.41881 46.2306 3.131 46.5148 2.77864 46.5148Z"
      fill="#3D3C42"
    />
    <path
      d="M27.7019 27.5638C27.6147 27.5638 27.5275 27.5465 27.442 27.5086C26.9361 27.2865 26.3971 27.0729 25.7918 26.8559C23.8207 26.1481 21.3682 25.2698 21.3682 22.392V21.8306C21.3682 21.481 21.656 21.1985 22.0101 21.1985C22.3642 21.1985 22.6503 21.4827 22.6503 21.8306V22.392C22.6503 24.2434 24.0684 24.8909 26.2297 25.6659C26.8629 25.8932 27.4298 26.1171 27.9635 26.3513C28.2862 26.4925 28.4328 26.868 28.288 27.1866C28.1816 27.4225 27.9478 27.562 27.7019 27.562V27.5638Z"
      fill="#3D3C42"
    />
    <path
      d="M18.935 17.4011H18.5913C18.2372 17.4011 17.9512 17.1169 17.9512 16.7673C17.9512 16.4177 18.239 16.1335 18.5913 16.1335H18.935C19.099 16.1335 19.2315 16.0027 19.2315 15.8408V13.8861C19.2315 12.1122 20.1316 11.4543 20.9026 11.4302C21.2759 11.4182 22.6522 11.4182 22.7098 11.4182C23.0639 11.4182 23.3517 11.7023 23.3517 12.0519C23.3517 12.4015 23.0639 12.6857 22.7098 12.6857C22.7098 12.6891 21.3073 12.6857 20.9427 12.696C20.7334 12.7029 20.5119 13.1214 20.5119 13.8861V15.8408C20.5119 16.7001 19.8037 17.3994 18.9332 17.3994L18.935 17.4011Z"
      fill="#3D3C42"
    />
    <path
      d="M18.4236 23.5286C15.0431 23.5286 11.1113 20.067 11.1113 15.6048V12.126C11.1113 11.7764 11.3991 11.4922 11.7515 11.4922C12.1039 11.4922 12.3934 11.7764 12.3934 12.126V15.6048C12.3934 19.2938 15.692 22.2628 18.4236 22.2628C19.4144 22.2628 20.5849 21.8581 21.6367 21.152C21.928 20.9557 22.3275 21.0297 22.5281 21.3191C22.7269 21.6084 22.6519 22.0011 22.3589 22.1991C21.082 23.0568 19.6865 23.5286 18.4254 23.5286H18.4236Z"
      fill="#3D3C42"
    />
    <path
      d="M25.4292 13.6036C25.0751 13.6036 24.7891 13.3195 24.7891 12.9699V8.75048C24.7891 8.40088 25.0769 8.11671 25.4292 8.11671C25.7816 8.11671 26.0694 8.40088 26.0694 8.75048V12.9699C26.0694 13.3195 25.7816 13.6036 25.4292 13.6036Z"
      fill="#3D3C42"
    />
    <path
      d="M16.4549 12.7598H14.7454C14.3913 12.7598 14.1035 12.4756 14.1035 12.126C14.1035 11.7764 14.3913 11.4922 14.7454 11.4922H16.4549C16.809 11.4922 17.0951 11.7764 17.0951 12.126C17.0951 12.4756 16.8073 12.7598 16.4549 12.7598Z"
      fill="#3D3C42"
    />
    <path
      d="M9.61441 11.4939C9.31438 11.4939 9.0475 11.2856 8.98644 10.9859C8.13171 6.76478 10.1552 3.31006 14.1427 2.18546C14.4811 2.08902 14.837 2.28363 14.9347 2.61946C15.0324 2.95529 14.8352 3.30661 14.4951 3.40133C11.1983 4.33132 9.52894 7.21083 10.2441 10.7379C10.3139 11.0806 10.0889 11.4147 9.74175 11.4819C9.69988 11.4905 9.65627 11.4939 9.61441 11.4939Z"
      fill="#3D3C42"
    />
    <path
      d="M27.568 12.2345C27.5052 12.2345 27.4407 12.2259 27.3761 12.2052C27.0377 12.1001 26.8493 11.7454 26.9557 11.413C27.4703 9.78379 27.1965 8.55587 26.783 7.12128C26.6871 6.78545 26.8842 6.43412 27.2244 6.3394C27.5663 6.2464 27.9186 6.43929 28.0163 6.77512C28.4559 8.3027 28.7978 9.83374 28.1803 11.7902C28.0948 12.0605 27.8419 12.2345 27.568 12.2345Z"
      fill="#3D3C42"
    />
    <path
      d="M11.7534 12.7597C11.7325 12.7597 11.7115 12.7597 11.6906 12.7563C11.3382 12.7236 11.0801 12.4136 11.1132 12.0657C11.1324 11.8659 11.633 7.19016 15.563 6.63562C16.662 6.48062 18.0836 6.82161 19.5855 7.18328C21.8479 7.72921 24.4121 8.34576 26.0257 7.27111C26.9763 6.63906 27.5677 5.46452 27.8328 3.68033C27.8851 3.33417 28.2113 3.09478 28.5602 3.14645C28.9108 3.19811 29.1533 3.51844 29.1009 3.8646C28.78 6.02079 28.0072 7.47949 26.7408 8.32165C24.6546 9.70802 21.7991 9.02086 19.2803 8.41465C17.9005 8.08226 16.6009 7.77055 15.7427 7.88938C12.8 8.30443 12.3918 12.1484 12.3883 12.1863C12.3569 12.5135 12.0778 12.758 11.7516 12.758L11.7534 12.7597Z"
      fill="#3D3C42"
    />
    <path
      d="M28.222 4.40534C26.6468 4.40534 25.6543 3.76296 24.6024 3.08098C23.6239 2.44721 22.6104 1.79105 20.8957 1.45178C16.5732 0.59929 15.1882 2.9897 15.1306 3.09131C14.9614 3.39614 14.5724 3.5098 14.2637 3.34619C13.9532 3.18086 13.8346 2.8037 13.9985 2.49543C14.0718 2.35938 15.8458 -0.838744 21.1469 0.208352C23.1058 0.595846 24.3251 1.38633 25.3054 2.0201C26.2683 2.64354 27.0288 3.13781 28.2202 3.13781C28.5743 3.13781 28.8621 3.42197 28.8621 3.77158C28.8621 4.12118 28.5743 4.40534 28.2202 4.40534H28.222Z"
      fill="#3D3C42"
    />
    <path
      d="M18.5305 29.2085C15.2092 29.2085 12.6084 27.5431 12.6084 25.4179C12.6084 25.0683 12.8962 24.7841 13.2486 24.7841C13.6009 24.7841 13.8888 25.0683 13.8888 25.4179C13.8888 26.787 16.0134 27.9426 18.5287 27.9426C21.0441 27.9426 23.1687 26.7853 23.1687 25.4179C23.1687 25.0683 23.4565 24.7841 23.8089 24.7841C24.1612 24.7841 24.449 25.0683 24.449 25.4179C24.449 27.5431 21.8482 29.2085 18.527 29.2085H18.5305Z"
      fill="#3D3C42"
    />
    <path
      d="M15.5999 14.8694C15.9545 14.8694 16.2418 14.5857 16.2418 14.2357C16.2418 13.8857 15.9545 13.6019 15.5999 13.6019C15.2454 13.6019 14.958 13.8857 14.958 14.2357C14.958 14.5857 15.2454 14.8694 15.5999 14.8694Z"
      fill="#3D3C42"
    />
    <path
      d="M22.0101 14.8694C22.3646 14.8694 22.652 14.5857 22.652 14.2357C22.652 13.8857 22.3646 13.6019 22.0101 13.6019C21.6556 13.6019 21.3682 13.8857 21.3682 14.2357C21.3682 14.5857 21.6556 14.8694 22.0101 14.8694Z"
      fill="#3D3C42"
    />
  </svg>
)

export default Topic20009300Icon
