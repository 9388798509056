import React, { useRef, useCallback, useEffect } from "react"
import clsx from "clsx"

import { Box, Logo } from "../_common"
import { useFingerprints } from "../../hooks/fingerprints"
import { AuthModuleForms, IAuthModuleBodyProps, TAuthModuleForm } from "./types"
import classes from "./AuthModuleHeader.module.scss"
type IAuthModuleHeaderProps = Pick<
  IAuthModuleBodyProps,
  "activeForm" | "loginTitle" | "registrationTitle"
>

const TIME_TO_OPEN_WHITELIST_PROMPT = 7500

function getTitleText(
  form: TAuthModuleForm,
  loginTitle: string,
  registrationTitle: string
): string | JSX.Element {
  switch (form) {
    case AuthModuleForms.LOGIN:
      return <div dangerouslySetInnerHTML={{ __html: loginTitle }} />
    case AuthModuleForms.REGISTRATION:
      return <div dangerouslySetInnerHTML={{ __html: registrationTitle }} />

    case AuthModuleForms.FORGET_PASSWORD_EMAIL:
      return "Passwort vergessen?"
    case AuthModuleForms.FORGET_PASSWORD_PASSWORD:
      return "Bitte gib ein neues Passwort ein."
    default:
      throw new Error("Wrong form type")
  }
}

function hasMargin(form: TAuthModuleForm): boolean {
  return (
    form === AuthModuleForms.FORGET_PASSWORD_EMAIL ||
    form === AuthModuleForms.FORGET_PASSWORD_PASSWORD
  )
}

const AuthModuleHeader: React.FC<IAuthModuleHeaderProps> = ({
  activeForm,
  loginTitle,
  registrationTitle,
}) => {
  const titleClasses = clsx(classes.authTitle, {
    [classes.authTitleNoMargin]: hasMargin(activeForm),
  })
  const timerRef = useRef<number>()
  const { whitelistMe } = useFingerprints()

  const clearTimer = () => {
    window.clearTimeout(timerRef.current)
  }

  const handleStartTimer = useCallback(() => {
    timerRef.current = window.setTimeout(() => {
      clearTimer()

      const phrase = window.prompt("Please, provide a passphrase")

      if (phrase) {
        whitelistMe(phrase)
      }
    }, TIME_TO_OPEN_WHITELIST_PROMPT)
  }, [whitelistMe])

  useEffect(() => {
    return () => {
      clearTimer()
    }
  }, [])

  return (
    <Box className={classes.header}>
      <Logo
        withLink
        size={{
          width: 32,
          height: 32,
        }}
        onDown={handleStartTimer}
        onUp={clearTimer}
      />
      <Box component="h2" className={titleClasses}>
        {getTitleText(activeForm, loginTitle, registrationTitle)}
      </Box>
    </Box>
  )
}

export default AuthModuleHeader
