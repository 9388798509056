import React from "react"
import { IIconProps } from "../../_common/QuesticoIcon/types"

const Mood3Icon: React.FC<IIconProps> = ({
  height = "100%",
  width = "100%",
}) => (
  <svg width={width} height={height} viewBox="0 0 47 47">
    <path
      d="M12.0332 35.4005L12.7305 35.4619L12.8623 33.9646L11.6314 34.8272L12.0332 35.4005ZM12.0332 35.4005C12.7305 35.4619 12.7304 35.4619 12.7304 35.462L12.7304 35.4622L12.7304 35.4628L12.7302 35.4651L12.7294 35.4737L12.7264 35.5062L12.715 35.6285C12.705 35.7339 12.6903 35.8847 12.6712 36.0679C12.6331 36.4338 12.5776 36.931 12.5081 37.4535C12.4389 37.974 12.3545 38.529 12.2578 39.0073C12.1688 39.4482 12.0494 39.9356 11.8745 40.239C11.2446 41.3317 9.84845 41.7036 8.75776 41.0748C7.66505 40.4449 7.29318 39.0487 7.92197 37.958C8.09711 37.6542 8.45975 37.3068 8.7969 37.0093C9.16285 36.6863 9.60129 36.3357 10.0175 36.0155C10.4353 35.694 10.8382 35.3974 11.1361 35.1815C11.2853 35.0734 11.4085 34.9852 11.4947 34.9239L11.595 34.8529L11.6217 34.834L11.6287 34.8291L11.6306 34.8278L11.6312 34.8274L11.6313 34.8273C11.6314 34.8272 11.6314 34.8272 12.0332 35.4005Z"
      fill="#87E5DB"
      stroke="#3D3C42"
      strokeWidth="1.4"
    />
    <path
      d="M32.2958 35.4003L32.9931 35.4618L33.125 33.9645L31.8941 34.8271L32.2958 35.4003ZM32.2958 35.4003C32.9931 35.4618 32.9931 35.4618 32.9931 35.4619L32.9931 35.4621L32.9931 35.4627L32.9929 35.465L32.9921 35.4736L32.9891 35.5061L32.9777 35.6284C32.9677 35.7337 32.953 35.8846 32.9339 36.0678C32.8958 36.4337 32.8403 36.9309 32.7708 37.4534C32.7016 37.9739 32.6172 38.5289 32.5205 39.0072C32.4315 39.4481 32.3121 39.9355 32.1372 40.2389C31.5073 41.3316 30.1111 41.7035 29.0205 41.0747C27.9277 40.4447 27.5559 39.0486 28.1847 37.9579C28.3598 37.6541 28.7224 37.3067 29.0596 37.0092C29.4256 36.6862 29.864 36.3356 30.2802 36.0154C30.698 35.6939 31.1009 35.3973 31.3988 35.1814C31.548 35.0733 31.6712 34.9851 31.7574 34.9238L31.8577 34.8527L31.8844 34.8339L31.8914 34.829L31.8933 34.8276L31.8939 34.8273L31.894 34.8272C31.8941 34.8271 31.8941 34.8271 32.2958 35.4003Z"
      fill="#87E5DB"
      stroke="#3D3C42"
      strokeWidth="1.4"
    />
    <path
      d="M22.1317 38.8658C21.7298 38.2927 21.7297 38.2927 21.7297 38.2927L21.7295 38.2929L21.7289 38.2933L21.7267 38.2949L21.7183 38.3007L21.6864 38.3232C21.6587 38.3428 21.6182 38.3715 21.5666 38.4082C21.4634 38.4816 21.3158 38.5873 21.1372 38.7168C20.7803 38.9756 20.298 39.331 19.798 39.7158C19.2996 40.0996 18.7763 40.5183 18.3405 40.903C17.9337 41.2622 17.5163 41.665 17.3195 42.0065C16.6037 43.2487 17.0281 44.8362 18.2702 45.5548L18.2712 45.5554C19.5134 46.2713 21.1009 45.8468 21.8195 44.6047L21.8201 44.6037C22.0167 44.2626 22.1565 43.7 22.2639 43.1678C22.379 42.5981 22.4798 41.9355 22.5627 41.3119C22.6459 40.6865 22.7124 40.091 22.7581 39.6525C22.7809 39.4331 22.7986 39.2524 22.8106 39.1264C22.8166 39.0633 22.8211 39.0139 22.8242 38.9801L22.8278 38.9412L22.8287 38.931L22.8289 38.9283L22.829 38.9276L22.829 38.9274C22.829 38.9273 22.829 38.9272 22.1317 38.8658ZM22.1317 38.8658L21.7298 38.2927L22.961 37.4292L22.829 38.9272L22.1317 38.8658Z"
      fill="#87E5DB"
      stroke="#3D3C42"
      strokeWidth="1.4"
    />
    <path
      d="M40.5019 12.1197L40.4016 12.9081H41.0916C43.9403 13.8212 45.9323 16.4945 45.9323 19.5484C45.9323 23.3946 42.8027 26.5258 38.957 26.5258H26.9767C26.9884 26.3783 26.9945 26.229 26.9945 26.0782C26.9945 23.9254 25.7667 21.9618 23.9071 20.9837C23.8705 16.861 20.507 13.52 16.3757 13.52C14.2574 13.52 12.2506 14.415 10.8344 15.9532C10.3239 15.8216 9.79478 15.7531 9.26702 15.7531C7.78131 15.7531 6.41592 16.264 5.34101 17.1238C6.09103 15.1208 7.74192 13.5174 9.83928 12.8777L10.3162 12.7322L10.3346 12.234C10.4903 8.02486 13.9643 4.64976 18.2098 4.64976C19.3149 4.64976 20.4091 4.88339 21.4138 5.32971L21.941 5.5639L22.2716 5.0912C24.1434 2.41501 27.2447 0.781116 30.525 0.781116C36.0706 0.781116 40.5832 5.29371 40.5832 10.8393C40.5832 11.2667 40.5561 11.6941 40.5019 12.1197Z"
      fill="#00A5BA"
      stroke="#3D3C42"
      strokeWidth="1.4"
    />
    <path
      d="M21.3521 22.731L21.2784 23.3165L21.8429 23.4882C23.393 23.9597 24.4833 25.4038 24.4833 27.0552C24.4833 29.1124 22.8078 30.7863 20.7523 30.7863H5.12051C3.0631 30.7863 1.38945 29.1126 1.38945 27.0552C1.38945 25.414 2.48417 23.9591 4.03094 23.4858L4.50698 23.3402L4.52565 22.8427C4.61084 20.5725 6.48494 18.7504 8.77619 18.7504C9.37284 18.7504 9.96424 18.8766 10.505 19.1178L11.033 19.3532L11.364 18.8792C12.3879 17.4131 14.0891 16.5173 15.8849 16.5173C18.9226 16.5173 21.3963 18.9892 21.3963 22.0287C21.3963 22.262 21.3815 22.4975 21.3521 22.731Z"
      fill="#CECECE"
      stroke="#3D3C42"
      strokeWidth="1.4"
    />
  </svg>
)

export default Mood3Icon
