import clsx from "clsx"
import React from "react"
import {
  FiltersModuleFieldId,
  IExpertsFilter,
  IFilterOption,
} from "../../types"
import { AdviqoButton, Box, Button, ButtonGroup, FormControl } from "../_common"
import { renderIcon } from "./helpers"
import classes from "./WizardButtonGroup.module.scss"

interface IWizardButtonGroup {
  filter: IExpertsFilter
  handleFilterChange: (activeOption: IFilterOption["id"]) => void
}

export const WizardButtonGroup: React.FC<IWizardButtonGroup> = ({
  filter,
  handleFilterChange,
}) => {
  const { id, options, defaultOption } = filter

  const groupClasses = clsx(classes.group, {
    [classes.method]: id === FiltersModuleFieldId.METHOD,
    [classes.topic]: id === FiltersModuleFieldId.TOPIC,
    [classes.productType]: id === FiltersModuleFieldId.PRODUCT_TYPE,
  })

  return (
    <FormControl className={classes.wrapper} data-testid={`${id}-filter`}>
      <ButtonGroup className={groupClasses}>
        {options
          .filter((option) => option.id !== "ALL")
          .map((option) =>
            id === FiltersModuleFieldId.PRODUCT_TYPE ? (
              <AdviqoButton
                key={option.id}
                label={option.name}
                testId={`productType-${option.name}`}
                variant="contained"
                color="primary"
                icon={renderIcon(option.id, classes.icon)!}
                buttonClassName={classes.button}
                labelClassName={classes.label}
                iconClassName={classes.icon}
                onClick={() => {
                  handleFilterChange(option.id)
                }}
              />
            ) : (
              <Button
                key={option.id}
                data-testid={`${id}-filter-${option.id}-option`}
                classes={{
                  root: clsx(classes.button, classes.label, {
                    [classes.active]: option.id === defaultOption,
                  }),
                }}
                onClick={() => {
                  handleFilterChange(option.id)
                }}
              >
                <Box className={classes.icon}>{renderIcon(option.id)}</Box>
                {option.name}
              </Button>
            )
          )}
      </ButtonGroup>
    </FormControl>
  )
}
