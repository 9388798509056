import React, { useCallback, useEffect } from "react"
import { useSearchParams } from "next/navigation"

import {
  AuthModuleForms,
  IAuthModuleBodyProps,
  IMessage,
  TUseFormValues,
} from "../types"
import { AdviqoButton, Box, LinkButton } from "../../_common"
import Input from "../components/Input"
import useForm from "../hooks/useForm"
import Message, { AuthMessageLink } from "../components/Message/Message"
import { useAuthentication } from "../../../hooks/authentication"
import { PageSlug } from "../../../types"
import { QUERY_TOKEN_VERIFICATION } from "../consts"
import useTrackClientError from "../hooks/useTrackClientError"
import { getRedirectUrl } from "../utils/redirects"
import classes from "./LoginForm.module.scss"

type IRegistrationFormProps = Pick<
  IAuthModuleBodyProps,
  "redirectToAuthForm" | "successLoginRedirectPage" | "standalone"
>

const validationHintMessage: IMessage = {
  type: "info",
  summary:
    "Du kannst auch deinen Mitgliedsnamen oder deine Telefonnummer verwenden.",
}

interface ILoginFormProps extends IRegistrationFormProps {
  loginFormPlaceholder: string
}

const LoginForm: React.FC<ILoginFormProps> = ({
  redirectToAuthForm,
  successLoginRedirectPage,
  loginFormPlaceholder,
  standalone,
}) => {
  const searchParams = useSearchParams()

  const { authenticate } = useAuthentication()

  const handleFormSubmit = useCallback(
    async (values: TUseFormValues): Promise<IMessage | void> => {
      const { username, password } = values
      if (username && password) {
        const errorData = await authenticate(
          { userName: username.value, password: password.value },
          AuthModuleForms.LOGIN,
          getRedirectUrl(successLoginRedirectPage, standalone)
        )

        if (errorData !== undefined) {
          let errorMessage: React.ReactNode = errorData.message

          // auth errors will return with 422 code
          if (errorData.code === 422) {
            errorMessage = (
              <>
                {errorData.message}&nbsp;
                <AuthMessageLink to={`/${PageSlug.PASSWORD_RECOVERY}`}>
                  Hier ein neues Passwort anfordern.
                </AuthMessageLink>
              </>
            )
          }

          return {
            type: "error",
            summary: errorMessage,
          }
        }
      }
    },
    [authenticate, standalone, successLoginRedirectPage]
  )

  const {
    register,
    errors,
    isDisabled,
    message,
    submitHandler,
    setMessage,
    values,
  } = useForm(AuthModuleForms.LOGIN, handleFormSubmit, validationHintMessage)

  useTrackClientError(values, errors, AuthModuleForms.LOGIN)

  useEffect(() => {
    if (searchParams.get(QUERY_TOKEN_VERIFICATION) === "success") {
      setMessage({
        type: "success",
        summary: "Passwort erfolgreich zurückgesetzt.",
      })
    }
  }, [searchParams, setMessage])

  return (
    <Box className={classes.root}>
      <form noValidate data-testid="login-form">
        {message && <Message message={message} />}
        <Box className={classes.formField}>
          <Input
            {...register({
              name: "username",
              initValue: searchParams.get("payload") as string,
              required: true,
            })}
            type="text"
            label={loginFormPlaceholder || "E-Mail-Adresse"}
            placeholder={loginFormPlaceholder || "E-Mail-Adresse"}
            error={errors.username}
            dataTestId="username"
          />
        </Box>
        <Box className={classes.formField}>
          <Input
            {...register({ name: "password", required: true })}
            type="password"
            label="Passwort oder PIN"
            placeholder="Passwort oder PIN"
            withVisibilityControl
            error={errors.password}
            dataTestId="password"
          />
        </Box>
        <Box className={classes.formField}>
          <LinkButton
            label="Passwort vergessen?"
            testId="reset-password-link"
            onClick={(e) => {
              e.preventDefault()
              const searchParams = new URLSearchParams()
              if (values.username?.value) {
                searchParams.set("payload", values.username.value)
              }
              redirectToAuthForm({
                pathname: `/${PageSlug.PASSWORD_RECOVERY}`,
                query: searchParams,
              })
            }}
          />
        </Box>
        <Box className={classes.formField}>
          <AdviqoButton
            labelClassName={classes.submitButton}
            fullWidth
            type="submit"
            label="Anmelden"
            labelCentered
            onClick={submitHandler}
            testId="login-form-submit-button"
            disabled={isDisabled}
          />
        </Box>
      </form>
    </Box>
  )
}

export default LoginForm
