"use client"

import { useRouter } from "next/navigation"
import React, { useCallback, useState } from "react"
import { ClientTrackingEventName } from "../../types"
import { AdviqoButton, ConfirmationModal, useTheme } from "../_common"
import CloseIcon from "../_common/QuesticoIcon/CloseIcon"
import { IUseWizardTracking } from "./types"
import classes from "./WizardClose.module.scss"

type IWizardCloseProps = Pick<IUseWizardTracking, "trackWizardStep"> & {
  closeModalTitle: string
  closeModalText: string
}

const WizardClose: React.FC<IWizardCloseProps> = ({
  closeModalTitle,
  closeModalText,
  trackWizardStep,
}) => {
  const theme = useTheme()
  const [isCloseModalOpen, setIsCloseModalOpened] = useState(false)

  const { push } = useRouter()

  const handleCloseModalConfirm = useCallback(() => {
    push("/")
    trackWizardStep({ eventName: ClientTrackingEventName.WIZARD_EXITED })
  }, [push, trackWizardStep])

  return (
    <>
      <AdviqoButton
        round
        color="custom"
        size="small"
        testId="close-wizard"
        buttonClassName={classes.closeAction}
        icon={
          <CloseIcon
            color={theme.palette.contextualColors.uiSecondary}
            width={20}
            height={20}
          />
        }
        onClick={() => setIsCloseModalOpened(true)}
      />

      <ConfirmationModal
        confirmationTitle={closeModalTitle as string}
        confirmationText={closeModalText as string}
        cancelButtonLabel="Nein, ich möchte bleiben"
        confirmButtonLabel="Ja, vielleicht später"
        opened={isCloseModalOpen}
        onCancel={() => setIsCloseModalOpened(false)}
        onConfirm={handleCloseModalConfirm}
        wrapperClassName={classes.modalWrapper}
        isInvertedActions
      />
    </>
  )
}

export default WizardClose
