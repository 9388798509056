import React, { useCallback, useContext } from "react"
import { FiltersModuleFieldId, IFilterOption } from "../../../types"
import FiltersContext from "../../Experts/Filters/context/FiltersContext"
import { FiltersActions } from "../../Experts/Filters/reducers/filtersReducer"
import { WizardButtonGroup } from "../WizardButtonGroup"
import { WizardStep } from "./Step"
import classes from "./Filter.module.scss"

interface IFilterStep {
  title: string
  type: FiltersModuleFieldId
  goToNextStep: (option?: string) => void
}

export const Filter: React.FC<IFilterStep> = ({
  title,
  type,
  goToNextStep,
}) => {
  const { state, dispatch } = useContext(FiltersContext)

  const filter = state.filters[type]

  const { id } = filter!

  const handleFilterChange = useCallback(
    (activeOption: IFilterOption["id"]) => {
      dispatch({
        type: FiltersActions.UPDATE_FILTER_ACTIVE_OPTION,
        payload: {
          id,
          activeOption,
        },
      })

      goToNextStep(activeOption.toString())
    },
    [dispatch, id, goToNextStep]
  )

  return (
    <WizardStep title={title} rootClassName={classes.root}>
      <WizardButtonGroup
        filter={filter!}
        handleFilterChange={handleFilterChange}
      />
    </WizardStep>
  )
}
