import React from "react"
import { IIconProps } from "../../_common/QuesticoIcon/types"

const Mood2Icon: React.FC<IIconProps> = ({
  height = "100%",
  width = "100%",
}) => (
  <svg width={width} height={height} viewBox="0 0 47 47">
    <path
      d="M34.1082 5.99716C33.9552 5.99716 33.8299 5.87184 33.8299 5.71882V1.6168C33.8299 1.46378 33.9552 1.33846 34.1082 1.33846C34.2612 1.33846 34.3866 1.46377 34.3866 1.6168V5.71882C34.3866 5.87185 34.2612 5.99716 34.1082 5.99716Z"
      fill="#A4F0C1"
      stroke="#3D3C42"
      strokeWidth="1.4"
    />
    <path
      d="M42.373 8.31576H42.3369C42.2609 8.31576 42.1934 8.2882 42.1387 8.23342C42.0303 8.1251 42.0303 7.94753 42.1387 7.83922L41.6437 7.34424L42.1387 7.83922L45.0386 4.93929C45.0926 4.88528 45.1606 4.85695 45.2368 4.85695C45.3141 4.85695 45.3796 4.88392 45.435 4.93929C45.489 4.99329 45.5173 5.06135 45.5173 5.13749C45.5173 5.2148 45.4904 5.28032 45.435 5.33569L42.5351 8.23562C42.4898 8.28092 42.4346 8.30816 42.373 8.31576Z"
      fill="#A4F0C1"
      stroke="#3D3C42"
      strokeWidth="1.4"
    />
    <path
      d="M26.0824 8.23562C26.0371 8.28092 25.9819 8.30816 25.9204 8.31576H25.8842C25.8083 8.31576 25.7408 8.2882 25.686 8.23342L22.7861 5.33349L22.3432 5.77637L22.7861 5.33349C22.6778 5.22518 22.6778 5.04761 22.7861 4.93929C22.8401 4.88528 22.9082 4.85695 22.9843 4.85695C23.0616 4.85695 23.1271 4.88392 23.1825 4.93929L23.1825 4.93929L26.0824 7.83922C26.1365 7.89322 26.1648 7.96128 26.1648 8.03742C26.1648 8.11473 26.1378 8.18025 26.0824 8.23562Z"
      fill="#A4F0C1"
      stroke="#3D3C42"
      strokeWidth="1.4"
    />
    <path
      d="M42.1378 24.7161L42.1378 24.7161C42.0838 24.6621 42.0555 24.5941 42.0555 24.5179C42.0555 24.4406 42.0824 24.3751 42.1378 24.3197C42.1932 24.2643 42.2587 24.2374 42.336 24.2374C42.4133 24.2374 42.4788 24.2644 42.5342 24.3197L45.4341 27.2196L45.9291 26.7247L45.4341 27.2196C45.5424 27.328 45.5424 27.5055 45.4341 27.6139C45.3801 27.6679 45.3121 27.6962 45.2359 27.6962H45.1995C45.1383 27.6887 45.0836 27.6619 45.0377 27.616L42.1378 24.7161Z"
      fill="#A4F0C1"
      stroke="#3D3C42"
      strokeWidth="1.4"
    />
    <path
      d="M44.6551 15.8574C44.5021 15.8574 44.3768 15.7321 44.3768 15.5791C44.3768 15.426 44.5021 15.3007 44.6551 15.3007H48.7571C48.9101 15.3007 49.0355 15.4261 49.0355 15.5791C49.0355 15.7321 48.9101 15.8574 48.7571 15.8574H44.6551Z"
      fill="#A4F0C1"
      stroke="#3D3C42"
      strokeWidth="1.4"
    />
    <path
      d="M37.5342 22.4184C37.4686 17.0439 33.7097 12.5483 28.6843 11.3449C30.0007 9.9563 31.8562 9.09062 33.911 9.09062C37.8896 9.09062 41.1277 12.3287 41.1277 16.3073C41.1277 18.9184 39.7291 21.2084 37.6365 22.4747C37.6026 22.4558 37.5685 22.437 37.5342 22.4184Z"
      fill="#A4F0C1"
      stroke="#3D3C42"
      strokeWidth="1.4"
    />
    <path
      d="M33.9857 24.69L33.9653 25.005L34.1879 25.2289L34.1901 25.2311L34.2933 25.3349L34.4296 25.3887C36.9697 26.3904 38.7114 28.8725 38.7114 31.6846C38.7114 35.4174 35.6732 38.4538 31.9423 38.4538H8.13519C4.40236 38.4538 1.36602 35.4155 1.36602 31.6846C1.36602 28.8605 3.14778 26.3446 5.7266 25.3584L6.13116 25.2037L6.17325 24.7726C6.54662 20.9485 9.78254 17.9486 13.7025 17.9486C14.5863 17.9486 15.4618 18.1033 16.2844 18.4022L16.7601 18.5751L17.0733 18.1775C18.8576 15.9129 21.6198 14.5463 24.5301 14.5463C29.7548 14.5463 34.0084 18.7979 34.0084 24.0246C34.0084 24.2443 34.0002 24.4659 33.9857 24.69Z"
      fill="#CECECE"
      stroke="#3D3C42"
      strokeWidth="1.4"
    />
  </svg>
)

export default Mood2Icon
