import { useState, useCallback, useEffect, Dispatch } from "react"

import {
  IMessage,
  IAuthInput,
  TUseFormErrors,
  TUseFormValues,
  TInputName,
  TAuthModuleForm,
} from "../types"
import { validate } from "../utils/validation"
import { useFingerprints } from "../../../hooks/fingerprints"

interface IRegisterOptions {
  name: TInputName
  required: boolean
  initValue?: string
}

interface IRegister {
  name: TInputName
  required: boolean
  value: string
  onChange: (event: React.ChangeEvent<IAuthInput>) => void
}

interface IUseForm {
  register: (opt: IRegisterOptions) => IRegister
  errors: TUseFormErrors
  isDisabled: boolean
  message?: IMessage
  setMessage: Dispatch<IMessage>
  values: TUseFormValues
  submitHandler: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<void>
}

function isNotValid(errors: TUseFormErrors): boolean {
  const isNotValid = !!Object.values(errors).find(
    (inputError) => inputError !== null
  )

  return isNotValid
}

const useForm = (
  formType: TAuthModuleForm,
  handleSubmitCallback?: (values: TUseFormValues) => Promise<IMessage | void>,
  validationHintMessage?: IMessage
): IUseForm => {
  const [values, setValues] = useState<TUseFormValues>({})
  const [errors, setErrors] = useState<TUseFormErrors>({})
  const [isDisabled, setIsDisabled] = useState(false)
  const [message, setMessage] = useState<IMessage>()
  const { isDeviceAllowed, isSubmitAllowed } = useFingerprints()

  useEffect(() => {
    setIsDisabled(!isDeviceAllowed)
  }, [isDeviceAllowed])

  const onChange = useCallback(
    (event: React.ChangeEvent<IAuthInput>) => {
      const { value: raw, name, required } = event.currentTarget
      const value = raw.replace(" ", "")

      if (errors[name] !== null) setErrors({ ...errors, [name]: null })

      setValues((values) => ({ ...values, [name]: { value, required } }))
    },
    [errors]
  )

  const submitHandler = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault()

      setIsDisabled(true)

      const errors = validate(values, formType)

      setErrors(errors)

      if (isNotValid(errors)) {
        if (validationHintMessage) setMessage(validationHintMessage)

        setIsDisabled(false)
        return
      }

      if ((await isSubmitAllowed(formType)) && handleSubmitCallback) {
        const message = await handleSubmitCallback(values)

        if (message) {
          setMessage(message)

          if (message.type === "error") {
            setIsDisabled(false)
          }
        }
      }
    },
    [
      formType,
      handleSubmitCallback,
      validationHintMessage,
      values,
      isSubmitAllowed,
    ]
  )

  const register = useCallback(
    ({ name, required, initValue }: IRegisterOptions): IRegister => {
      const registeredInput = values[name]
      const value = initValue ?? ""

      if (registeredInput) {
        return { onChange, name, required, value: registeredInput.value }
      }

      setValues((values) => ({ ...values, [name]: { value, required } }))

      return { onChange, name, required, value }
    },
    [onChange, values]
  )

  return {
    register,
    errors,
    isDisabled,
    message,
    submitHandler,
    setMessage,
    values,
  }
}

export default useForm
