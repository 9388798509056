import {
  TInputValidation,
  TInputName,
  TUseFormValues,
  TUseFormErrors,
  TAuthModuleForm,
  AuthModuleForms,
} from "../types"
import regExp from "./regExp"

export function validateInputValue(
  value: string | boolean,
  name: TInputName,
  required: boolean,
  formType: TAuthModuleForm
): TInputValidation {
  if (required && !value) return "isRequired"

  // TODO: do we still need this?
  // temporaly we don't validate login page
  if (formType === AuthModuleForms.LOGIN) return null

  if (typeof value === "boolean") {
    return null
  }

  switch (name) {
    case "username": {
      const isValidEmail = regExp.email.test(value)
      const isValidPhone = regExp.phone.test(value)

      if (!isValidPhone && !isValidEmail) return "invalidPhoneOrEmail"
      break
    }
    case "email":
      if (!regExp.email.test(value)) return "invalidEmail"
      break
    case "password":
    case "password-new":
    case "password-confirm":
      if (value.length < 6) return "shortPassword"
      break
    case "promocode":
      return null
    default:
      throw new Error("Wrong input name")
  }

  return null
}

export function validate(
  values: TUseFormValues,
  formType: TAuthModuleForm
): TUseFormErrors {
  const errors: TUseFormErrors = {}
  const names = Object.keys(values) as Array<keyof typeof values>

  names.forEach((name) => {
    const inputValue = values[name]

    if (inputValue) {
      const { value, required } = inputValue

      errors[name] = validateInputValue(value, name, required, formType)
    }
  })

  return errors
}
