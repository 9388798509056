import React from "react"

import StickableBox from "../../_common/StickableBox"
import Products from "./Products"

interface IStickableProductsProps
  extends React.ComponentProps<typeof Products> {
  stickableClassName?: string
  productsListClassName?: string
  showServiceFee?: boolean
  children?: React.ReactNode
}

const StickableProducts: React.FC<IStickableProductsProps> = ({
  stickableClassName = "",
  children,
  ...productsProps
}) => {
  return (
    <StickableBox stickableClassName={stickableClassName}>
      {children}

      <Products {...productsProps} />
    </StickableBox>
  )
}

export default StickableProducts
