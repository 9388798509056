"use client"

import React, { useCallback, useEffect } from "react"
import clsx from "clsx"
import { useTranslations } from "next-intl"

import { useSearchParams } from "next/navigation"
import { AdviqoButton, Box, Typography, LinkButton } from "../../_common"
import Input from "../components/Input"
import useForm from "../hooks/useForm"
import ArrowIcon from "../../_common/QuesticoIcon/ArrowIcon"
import {
  AuthModuleForms,
  DEFAULT_AUTH_ERROR_MESSAGE,
  IAuthModuleBodyProps,
  IMessage,
  TUseFormValues,
} from "../types"
import Message from "../components/Message/Message"
import { useAuthentication } from "../../../hooks/authentication"
import { QUERY_TOKEN_VERIFICATION } from "../consts"
import useTrackClientError from "../hooks/useTrackClientError"
import { PageSlug } from "../../../types"
import ServiceCenterPhoneNumber from "../../ServiceCenterPhoneNumber"
import classes from "./ForgetPasswordEmailForm.module.scss"

type IForgetPasswordEmailFormProps = Pick<
  IAuthModuleBodyProps,
  "redirectToAuthForm"
>

const ForgetPasswordEmailForm: React.FC<IForgetPasswordEmailFormProps> = ({
  redirectToAuthForm,
}) => {
  const searchParams = useSearchParams()
  const tokenVerificationParam = searchParams.get(QUERY_TOKEN_VERIFICATION)

  const { sendResetPasswordLink } = useAuthentication()
  const translate = useTranslations()

  const handleFormSubmit = useCallback(
    async (values: TUseFormValues): Promise<IMessage | void> => {
      const { username } = values
      if (username) {
        const status = await sendResetPasswordLink(username.value)

        // In case of server error, show error message, otherwise show success message
        if (status !== undefined && status >= 500 && status <= 599) {
          return {
            type: "error",
            summary: DEFAULT_AUTH_ERROR_MESSAGE,
          }
        }

        return {
          type: "success",
          summary: (
            <>
              <Typography component="div" className={classes.summary}>
                Die Anweisungen zum Zurücksetzen wurden an {username.value}{" "}
                gesendet.
              </Typography>
            </>
          ),
        }
      }
    },
    [sendResetPasswordLink]
  )

  const {
    register,
    errors,
    isDisabled,
    message,
    submitHandler,
    setMessage,
    values,
  } = useForm(AuthModuleForms.FORGET_PASSWORD_EMAIL, handleFormSubmit)

  useTrackClientError(values, errors, AuthModuleForms.FORGET_PASSWORD_EMAIL)

  useEffect(() => {
    if (tokenVerificationParam === "failed") {
      setMessage({
        type: "error",
        summary: translate("resetPasswordError"),
      })
    }
  }, [tokenVerificationParam, setMessage])

  const shouldShowFormControls = message?.type !== "success"
  return (
    <Box className={classes.root}>
      <form noValidate data-testid="reset-credentials-form">
        <Box className={classes.formWrapper}>
          {message && <Message message={message} />}
          {!shouldShowFormControls && (
            <Box className={classes.formField}>
              <Typography component="p">
                Hast du keinen Link zum Zurücksetzen erhalten?
              </Typography>
              <Typography component="ol" className={classes.list}>
                <Typography component="li">Prüfe den Spam-Ordner.</Typography>
                <Typography component="li">
                  Überprüfe die Rechtschreibung.
                </Typography>
                <Typography component="li">
                  Warte 15 Minuten, bevor du es erneut versuchst, da einige
                  Anfragen nur langsam bearbeitet werden können.
                </Typography>
              </Typography>
            </Box>
          )}
          {shouldShowFormControls && (
            <>
              <Box className={classes.formField}>
                <Box component="p" className={classes.formFieldText}>
                  {translate("forgotPasswordFormTitle")}
                </Box>
              </Box>
              <Box className={classes.formField}>
                <Input
                  {...register({
                    name: "username",
                    initValue: searchParams.get("payload") as string,
                    required: true,
                  })}
                  type="email"
                  label="E-Mail-Adresse"
                  placeholder="E-Mail-Adresse"
                  error={errors.username}
                  isDisabled={isDisabled}
                  dataTestId="email-address"
                />
              </Box>
              <Box className={classes.formField}>
                <AdviqoButton
                  labelClassName={classes.submitButton}
                  fullWidth
                  type="submit"
                  label="Link senden"
                  labelCentered
                  onClick={submitHandler}
                  testId="forget-password-email-submit-button"
                  disabled={isDisabled}
                />
              </Box>
            </>
          )}
          <Box className={clsx(classes.formField, classes.formFieldCenter)}>
            <LinkButton
              label="Zurück zur Anmeldung"
              testId="back-to-login-link"
              onClick={(e) => {
                e.preventDefault()
                redirectToAuthForm({
                  pathname: PageSlug.LOGIN,
                  query: new URLSearchParams(),
                })
              }}
              icon={<ArrowIcon className={classes.arrow} />}
              buttonClassName={classes.linkButton}
            />
          </Box>
        </Box>
        <Box className={clsx(classes.formField, classes.formFieldBoxed)}>
          <Box component="p" className={classes.formFieldText}>
            {translate.rich("forgotPasswordForgotEmailText", {
              phoneLink: () => (
                <ServiceCenterPhoneNumber
                  useOnlyOuterStylingOrPlain
                  className={classes.link}
                  testId="datenschutz-external-link"
                />
              ),
            })}
          </Box>
        </Box>
      </form>
    </Box>
  )
}

export default ForgetPasswordEmailForm
