"use client"

import React, { useCallback, useEffect, useState } from "react"

import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react"
import { LOCAL_STORAGE_KEYS, TAuthenticationAction } from "../../types"
import { useRuntimeConfig } from "../runtimeConfig"
import { QProxyEndpoint, apiRequest } from "../../utils/rest"
import { AuthModuleForms, TAuthModuleForm } from "../../ui-lib/Auth/types"
import { storage } from "../../utils/localStorage"

interface IFingerprintContext {
  isAttemptAllowed: (formType?: TAuthModuleForm) => Promise<boolean>
  trackAttempt: () => Promise<boolean>
  whitelistMe: (phrase: string) => Promise<void>
  onAuthFormChange: (formType: TAuthModuleForm) => void
  isSubmitAllowed: (formType: TAuthModuleForm) => Promise<boolean>
  isDeviceAllowed: boolean
}

export const FingerprintContext = React.createContext<IFingerprintContext>({
  isAttemptAllowed: () => Promise.resolve(true),
  trackAttempt: () => Promise.resolve(true),
  whitelistMe: () => Promise.resolve(),
  onAuthFormChange: () => {},
  isSubmitAllowed: () => Promise.resolve(true),
  isDeviceAllowed: true,
})

const FingerprintProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { getRuntimeConfig } = useRuntimeConfig()
  const [isDeviceAllowed, setIsDeviceAllowed] = useState(true)

  const { FINGERPRINTS_IS_ENABLED, MANDATOR_NUMBER } = getRuntimeConfig()
  const { data, getData } = useVisitorData(
    {
      extendedResult: true,
    },
    { immediate: false }
  )

  useEffect(() => {
    if (data) {
      storage.setItem(LOCAL_STORAGE_KEYS.FINGERPRINTJS_GET_RESULT, data)
    }
  }, [data])

  const isAttemptAllowed = useCallback(
    async (formType?: TAuthModuleForm): Promise<boolean> => {
      try {
        const { visitorId } = await getData()
        // if visitor is not detected we assume it's a new user
        if (!visitorId) return true

        const page: TAuthenticationAction =
          formType === AuthModuleForms.REGISTRATION ? "register" : "login"

        const query = {
          visitorId,
          mandatorNo: MANDATOR_NUMBER,
          page,
        }

        const data = await apiRequest<{ allowed?: boolean }>(
          {
            url: QProxyEndpoint.FINGERPRINTS_ATTEMPT,
            service: "qproxy",
            query,
          },
          getRuntimeConfig
        )

        return Boolean(data?.allowed)
      } catch (e) {
        console.error(e)

        // we don't want to block users from login/registration if error happened on our side
        return true
      }
    },
    [MANDATOR_NUMBER, getRuntimeConfig]
  )

  const trackAttempt = useCallback(async () => {
    try {
      const { visitorId } = await getData()
      const data = await apiRequest<{ allowed: boolean }>(
        {
          url: QProxyEndpoint.FINGERPRINTS_ATTEMPT,
          method: "POST",
          service: "qproxy",
          body: {
            visitorId,
            mandatorNo: MANDATOR_NUMBER,
          },
        },
        getRuntimeConfig
      )
      return Boolean(data?.allowed)
    } catch (e) {
      console.error(e)

      // @ts-ignore
      if (e.status === 403 && e.name === "FORBIDDEN") return false

      // we don't want to block users from login/registration if error happened on our side
      return true
    }
  }, [MANDATOR_NUMBER, getRuntimeConfig])

  const whitelistMe = useCallback(
    async (phrase: string) => {
      try {
        const { visitorId } = await getData()

        if (!visitorId) {
          throw new Error(`visitorId is not valid or undefined`)
        }

        const data = await apiRequest<{ success: boolean }>(
          {
            url: QProxyEndpoint.FINGERPRINTS_WHITELIST,
            service: "qproxy",
            query: {
              visitorId,
              phrase,
            },
          },
          getRuntimeConfig
        )
        if (!data?.success) throw new Error(`Cannot whitelist "${visitorId}"`)
        window.alert("Your device was successfully whitelisted")
      } catch (e) {
        console.error(e)

        window.alert(
          "Something went wrong. Please try again, or contact someone"
        )
      }
    },
    [getRuntimeConfig]
  )

  const onAuthFormChange = useCallback(
    (formType: TAuthModuleForm) => {
      if (FINGERPRINTS_IS_ENABLED && formType !== null) {
        isAttemptAllowed(formType).then((allowed) =>
          setIsDeviceAllowed(allowed)
        )
      }
    },
    [FINGERPRINTS_IS_ENABLED, isAttemptAllowed]
  )

  const isSubmitAllowed = useCallback(
    async (formType: TAuthModuleForm) => {
      const isAllowed =
        formType === AuthModuleForms.REGISTRATION && FINGERPRINTS_IS_ENABLED
          ? await trackAttempt()
          : true

      setIsDeviceAllowed(isAllowed)
      return isAllowed
    },
    [FINGERPRINTS_IS_ENABLED, trackAttempt]
  )

  return (
    <FingerprintContext.Provider
      value={{
        isAttemptAllowed,
        whitelistMe,
        trackAttempt,
        onAuthFormChange,
        isSubmitAllowed,
        isDeviceAllowed,
      }}
    >
      {children}
    </FingerprintContext.Provider>
  )
}

export default FingerprintProvider
