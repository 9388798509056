import React from "react"
import { Box, Typography, Rating } from "../../_common"
import { IAdvisor } from "../../../types"
import { ZodiacSignIcon } from "../../_common/QuesticoIcon"
import CallIcon from "../../_common/QuesticoIcon/CallIcon"
import classes from "./ExpertStats.module.scss"

interface IListItem {
  title: string
  value: string | number
  children: React.ReactNode
}

const ListItem: React.FC<IListItem> = ({ title, value, children }) => {
  return (
    <Box component="li" className={classes.statsItem}>
      {children}
      <Typography component="span" className={classes.value}>
        {value}
      </Typography>
      <Typography component="span" className={classes.title}>
        {title}
      </Typography>
    </Box>
  )
}

type ExpertStatsProps = Pick<IAdvisor, "rating" | "sign"> & {
  readingsCount: IAdvisor["readings_count"]
}

const ExpertStats: React.FC<ExpertStatsProps> = ({
  rating,
  readingsCount,
  sign = null,
}) => {
  const { average_rounded: averageRounded, average } = rating

  return (
    <Box component="ul" className={classes.root}>
      <ListItem
        title={readingsCount === 1 ? "Gespräch" : "Gespräche"}
        value={readingsCount}
      >
        <CallIcon width={24} height={24} />
      </ListItem>

      <ListItem title="Bewertungen" value={average.toFixed(1)}>
        <Rating
          value={averageRounded}
          precision={0.5}
          readOnly
          size="small"
          className={classes.stars}
        />
      </ListItem>

      {sign && (
        <ListItem title="Sternzeichen" value={sign.name}>
          <Box className={classes.signIcon}>
            <ZodiacSignIcon variant={sign.alias} width={24} height={24} />
          </Box>
        </ListItem>
      )}
    </Box>
  )
}

export default ExpertStats
