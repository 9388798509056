import { parse } from "querystring"

import { IExpertEntry } from "../types"
import sentryUtils from "../../../utils/sentryUtils"
import { IAdvisorProductType } from "../../../types"

export function getExpertEntryFromQuery(query: string): IExpertEntry | null {
  try {
    const expertData = parse(query)
    const neededKeys = [
      "displayName",
      "price",
      "priceSale",
      "photo",
      "productType",
    ]
    if (!neededKeys.every((key) => Object.keys(expertData).includes(key))) {
      return null
    }

    const displayName = expertData.displayName as string
    const price = JSON.parse(expertData.price as string)
    const priceSale =
      expertData.priceSale === ""
        ? null
        : JSON.parse(expertData.priceSale as string)
    const photo = expertData.photo as string
    const productType = expertData.productType as IAdvisorProductType
    const expertEntry: IExpertEntry = {
      displayName,
      price,
      priceSale,
      photo,
      productType,
    }

    return expertEntry
  } catch (error) {
    console.error(error)

    sentryUtils.report({ error })

    return null
  }
}
