import React from "react"
import { IIconProps } from "../../_common/QuesticoIcon/types"

const WaveLightBlueBg: React.FC<IIconProps> = ({
  height = "100%",
  width = "100%",
}) => (
  <svg width={width} height={height} viewBox="0 0 8919 489">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8919 263.711V488H7525V229.222C7553.38 253.101 7611.7 290.456 7671 291.624C7718.08 292.552 7767.14 275.896 7812.12 260.625C7845.48 249.3 7876.6 238.736 7903 236.672C7965 231.823 8065 244.753 8149 312.636C8233 380.519 8381 281.927 8431 222.125C8481 162.324 8609 89.5918 8725 178.486C8790.02 228.316 8864.73 253.017 8919 263.711Z"
      fill="#D1F0F6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7250 26.5998V488.419H6056.17L6020 231.419C6077.33 215.419 6206.4 177.819 6264 155.419C6336 127.419 6424 97.4188 6576 155.419C6697.6 201.819 6844 125.419 6902 81.4188C6983.41 34.2543 7163.24 -40.2101 7250 26.5998Z"
      fill="#D1F0F6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5984 80.504V488.27H4590V163.024C4635.67 140.375 4719.27 110.445 4766 135.27C4790.33 148.197 4812.06 177.024 4835.59 208.233C4873.94 259.108 4917.06 316.311 4984 321.27C5035.55 325.088 5087.55 290.633 5143.49 253.571C5204.75 212.984 5270.73 169.27 5346 169.27C5455.2 169.27 5501.37 210.301 5532.83 238.266C5542.86 247.177 5551.39 254.762 5560 259.27C5591.5 275.77 5666 305.27 5780 123.27C5855.87 2.15136 5936.99 34.1003 5984 80.504Z"
      fill="#D1F0F6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4404 255.215V488.105H3010V126.469C3011.01 126.463 3012.03 126.457 3013.06 126.451C3061.14 126.163 3127.3 125.767 3170 161.105C3228 209.105 3264 213.105 3308 187.105C3312.73 184.309 3317.76 181.144 3323.09 177.792C3367.31 149.973 3431.89 109.341 3514 161.105C3606 219.105 3662 249.105 3744 259.105C3809.6 267.105 3946 197.105 4006 161.105C4060 133.105 4186 93.9046 4258 161.105C4318.64 217.7 4374.08 245.168 4404 255.215Z"
      fill="#D1F0F6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2945 130.494V487.593H1815C1818.33 444.926 1848.6 342.393 1943 273.593C2061 187.593 2225 213.593 2345 237.593C2465 261.593 2591 215.593 2701 165.593C2782.45 128.57 2890.74 126.385 2945 130.494Z"
      fill="#D1F0F6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M777.797 488.184H0V230.578C24.8801 260.704 82.8009 267.315 126.467 253.924C164.449 242.276 194.949 220.096 225.236 198.071C232.51 192.78 239.773 187.499 247.123 182.375C285.08 155.917 331.658 131.807 381.951 135.347C401.903 136.752 422.014 143.018 434.932 155.184C452.123 171.375 452.966 192.852 453.823 214.704C454.394 229.277 454.973 244.017 460.411 257.467C478.183 301.414 543.411 321.391 601.476 322.898C624.165 323.486 646.952 322.187 669.704 320.89C705.177 318.867 740.563 316.849 775.356 322C799.145 325.522 822.935 332.793 839.651 346.699C866.541 369.063 869.36 405.446 852.447 433.304C837.415 458.063 809.309 476.471 777.797 488.184Z"
      fill="#D1F0F6"
    />
    <path
      d="M776 322C832.499 320.002 854.001 291 1022.5 272.498C1191 253.996 1371.44 354.258 1597 340.998C1730.95 333.123 1920 287.5 1937.5 277.5C1955 267.5 1936 487.998 1936 487.998H771.001C771.001 487.998 719.501 323.998 776 322Z"
      fill="#D1F0F6"
    />
    <path
      d="M4403.98 255.241C4481 255.241 4589.98 163 4589.98 163L4595.98 488H4403.98C4403.98 488 4326.97 255.241 4403.98 255.241Z"
      fill="#D1F0F6"
    />
    <path
      d="M5973.43 69.6684C6027.7 113.288 5968.3 235.3 6067.79 219.287C6167.27 203.274 6067.79 488 6067.79 488H5973.43C5973.43 488 5844.21 -34.2001 5973.43 69.6684Z"
      fill="#D1F0F6"
    />
    <path d="M7250 26.5L7525 229.298V488H7250V26.5Z" fill="#D1F0F6" />
    <path d="M2937 130L3009.54 127L3014 488H2937V130Z" fill="#D1F0F6" />
  </svg>
)

export default WaveLightBlueBg
