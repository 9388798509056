import React from "react"

import { IIconProps } from "./types"

const SearchColoredIcon: React.FC<IIconProps> = ({
  width = "100%",
  height = "100%",
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 68 68" fill="transparent">
      <circle cx="26" cy="26" r="22.5" stroke="#72757E" strokeWidth="7" />
      <rect
        x="45.6426"
        y="39"
        width="30.6406"
        height="9.39945"
        rx="3"
        transform="rotate(44.9629 45.6426 39)"
        fill="#72757E"
      />
      <rect
        x="56"
        y="62.6494"
        width="9.41004"
        height="2.56227"
        transform="rotate(-44.96 56 62.6494)"
        fill="#9EDDE5"
      />
      <rect
        x="15.9727"
        y="32.0801"
        width="23.3087"
        height="2.35804"
        rx="1.17902"
        transform="rotate(-51.5661 15.9727 32.0801)"
        fill="#9EDDE5"
      />
      <rect
        x="21"
        y="37.2583"
        width="23.3087"
        height="2.35804"
        rx="1.17902"
        transform="rotate(-51.5661 21 37.2583)"
        fill="#9EDDE5"
      />
    </svg>
  )
}

export default SearchColoredIcon
