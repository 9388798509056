import React, { useCallback, useEffect } from "react"
import SwipeableViews from "react-swipeable-views"
import { Box } from "../_common"
import { useSwipable } from "./hooks/useSwipable"
import { WizardWave } from "./WizardWave"
import { WizardStepper } from "./WizardStepper"
import { WizardFilterStep, WizardMoodStep } from "./steps"
import {
  FiltersModuleFieldId,
  IWizardStepTypes,
  ClientTrackingEventName,
} from "../../types"
import { IUseWizardTracking, IWizardStepProps } from "./types"
import classes from "./WizardCustomerSteps.module.scss"

type IWizardCustomerStepsProps = IUseWizardTracking & {
  goToAuthScreen: () => void
  goToIntroScreen: () => void
  isCustomerStepsActive: boolean
  steps: IWizardStepProps[]
}

const renderStep = (
  step: IWizardStepProps,
  goToNextStep: (option?: string) => void
): JSX.Element | null => {
  const { type, title } = step

  switch (type) {
    case IWizardStepTypes.MOOD: {
      return (
        <WizardMoodStep
          type={(type as unknown) as FiltersModuleFieldId} // todo consider to change type from FiltersModuleFieldId to IWizardStepType
          title={title}
          goToNextStep={goToNextStep}
        />
      )
    }

    case IWizardStepTypes.TOPIC:
    case IWizardStepTypes.METHOD:
    case IWizardStepTypes.PRODUCT_TYPE: {
      return (
        <WizardFilterStep
          type={(type as unknown) as FiltersModuleFieldId}
          title={title}
          goToNextStep={goToNextStep}
        />
      )
    }

    default:
      return null
  }
}

export const WizardCustomerSteps: React.FC<IWizardCustomerStepsProps> = ({
  steps,
  goToAuthScreen,
  goToIntroScreen,
  customerStepsRef,
  trackWizardStep,
  isCustomerStepsActive,
}) => {
  const {
    activeStepIdx,
    stepsAmount,
    isLastStep,
    handleNext,
    handleBack,
    handleStepChange,
    isForward,
  } = useSwipable(steps.length)

  useEffect(() => {
    if (isCustomerStepsActive)
      trackWizardStep({ eventName: ClientTrackingEventName.WIZARD_STEP_VIEWED })
  }, [activeStepIdx])

  const goToPrevStep = useCallback(() => {
    if (activeStepIdx === 0) {
      goToIntroScreen()
    } else {
      handleBack()
    }
  }, [goToIntroScreen, handleBack, activeStepIdx])

  const goToNextStep = useCallback(
    (option?: string) => {
      trackWizardStep({
        eventName: ClientTrackingEventName.WIZARD_STEP_COMPLETED,
        choice: option,
      })

      if (isLastStep) {
        goToAuthScreen()
      } else {
        handleNext()
      }
    },
    [goToAuthScreen, handleNext, isLastStep, trackWizardStep]
  )

  return (
    <Box className={classes.fullScreen}>
      <WizardStepper
        stepsAmount={stepsAmount}
        handleBack={goToPrevStep}
        handleNext={goToNextStep}
        activeStepIdx={activeStepIdx}
      />
      {/* @ts-ignore */}
      <SwipeableViews
        index={activeStepIdx}
        onChangeIndex={(step: number) => {
          if (isForward(step)) {
            trackWizardStep({
              eventName: ClientTrackingEventName.WIZARD_STEP_COMPLETED,
              offset: 1,
            })
          }
          handleStepChange(step)
        }}
        enableMouseEvents
        className={classes.fullScreen}
        slideClassName={classes.fullScreen}
        ref={customerStepsRef}
      >
        {steps.map((step, i) => (
          <React.Fragment key={i}>
            {renderStep(step!, goToNextStep)}
          </React.Fragment>
        ))}
      </SwipeableViews>

      <WizardWave
        stepsAmount={steps.length + 1}
        activeStepIdx={activeStepIdx}
      />
    </Box>
  )
}
