import { useCallback, useEffect, useRef, useState } from "react"
import { useTranslations } from "next-intl"
import useSWRMutation from "swr/mutation"

import { useAuthentication } from "../../hooks/authentication"
import { useUser } from "../../hooks/user"
import { useRuntimeConfig } from "../../hooks/runtimeConfig"
import {
  fetchWithConfig,
  getCodeAndMessageFromApiError,
  IFetchWithConfigOptions,
  SWR_KEYS,
} from "../../utils/rest"
import { IPhoneInputState, IUsePhoneNumberState } from "./types"

interface IUpdatePhoneNumberOptions {
  code: string
  number: string
  id: number | undefined
  is_main: true
}

interface IPhoneStatusResponse {
  is_valid: boolean
  message: string | null
}
interface IPhoneStatusRequestBody {
  number: string
}

const usePhoneNumber = (checkPhoneStatusOnMount: boolean) => {
  const { getRuntimeConfig } = useRuntimeConfig()
  const { getPhoneNumber, fetchUserData } = useUser()
  const { phoneNumber: currentPhoneNumber, id } = getPhoneNumber()
  const needToCheckPhoneRef = useRef<boolean>(
    Boolean(checkPhoneStatusOnMount && currentPhoneNumber)
  )

  const translate = useTranslations()

  const { trigger: _updatePhoneNumber } = useSWRMutation<
    any,
    any,
    "updatePhoneNumber",
    IUpdatePhoneNumberOptions
  >(
    "updatePhoneNumber",
    async (_, options: IFetchWithConfigOptions<IUpdatePhoneNumberOptions>) => {
      return fetchWithConfig(
        {
          url: SWR_KEYS.UPDATE_PHONE_NUMBER,
          method: options.arg.id === undefined ? "POST" : "PATCH",
          headers: options.headers,
          data: options.arg,
        },
        getRuntimeConfig,
        "advice"
      )
    }
  )

  const { trigger: getPhoneNumberStatus } = useSWRMutation<
    IPhoneStatusResponse,
    any,
    "getPhoneNumberStatus",
    IPhoneStatusRequestBody
  >(
    "getPhoneNumberStatus",
    async (_key, options: IFetchWithConfigOptions<IPhoneStatusRequestBody>) => {
      return await fetchWithConfig<IPhoneStatusResponse>(
        {
          method: "POST",
          url: SWR_KEYS.GET_PHONE_NUMBER_STATUS,
          headers: options.headers,
          data: options.arg,
        },
        getRuntimeConfig,
        "advice"
      )
    }
  )

  const [phoneNumberState, setPhoneNumberState] = useState<
    IUsePhoneNumberState
  >({
    updatingPhoneNumber: undefined,
    updatePhoneNumberResult: undefined,
    phoneNumberError: undefined,
  })

  const { userId } = useAuthentication()

  const resetPhoneNumberState = () => {
    setPhoneNumberState({
      phoneNumberError: undefined,
      updatePhoneNumberResult: undefined,
      updatingPhoneNumber: undefined,
    })
  }

  const updatePhoneNumber = useCallback(
    async (inputState: IPhoneInputState) => {
      const { newPhoneNumber, countryCallingCode, nationalNumber } = inputState
      if (userId && newPhoneNumber && countryCallingCode && nationalNumber) {
        setPhoneNumberState({
          updatingPhoneNumber: true,
          phoneNumberError: undefined,
          updatePhoneNumberResult: undefined,
        })

        try {
          await _updatePhoneNumber({
            code: countryCallingCode,
            number: nationalNumber,
            is_main: true,
            id,
          })

          let result: IUsePhoneNumberState["updatePhoneNumberResult"]

          if (newPhoneNumber) {
            if (!currentPhoneNumber) {
              result = "phoneAdded"
            } else if (currentPhoneNumber !== newPhoneNumber) {
              result = "phoneChanged"
            } else {
              result = "phoneNotChanged"
            }
          }

          await fetchUserData()

          const nextState: IUsePhoneNumberState = {
            updatingPhoneNumber: false,
            updatePhoneNumberResult: result,
            phoneNumberError: undefined,
          }

          setPhoneNumberState(nextState)

          return nextState
        } catch (e) {
          const errorMessage = getCodeAndMessageFromApiError(
            e as any,
            translate("phoneNumber.updateDefaultError")
          ).message

          const nextState: IUsePhoneNumberState = {
            updatingPhoneNumber: false,
            updatePhoneNumberResult: "changeFailed",
            phoneNumberError: errorMessage,
          }

          setPhoneNumberState(nextState)

          return nextState
        }
      } else {
        const nextState: IUsePhoneNumberState = {
          updatingPhoneNumber: false,
          updatePhoneNumberResult: "changeFailed",
          phoneNumberError: translate("phoneNumber.updateDefaultError"),
        }

        console.warn(
          `Cannot update phone number. Wrong parameters. "userId: ${userId}", "phoneNumber: ${newPhoneNumber}"`
        )

        setPhoneNumberState(nextState)

        return nextState
      }
    },
    [
      _updatePhoneNumber,
      currentPhoneNumber,
      fetchUserData,
      id,
      translate,
      userId,
    ]
  )

  // get phone number status after mount
  useEffect(() => {
    if (currentPhoneNumber && needToCheckPhoneRef.current === true) {
      needToCheckPhoneRef.current = false
      getPhoneNumberStatus({ number: currentPhoneNumber })
        .then((result) => {
          if (result?.is_valid === false) {
            setPhoneNumberState({
              updatePhoneNumberResult: "changeRequired",
              updatingPhoneNumber: undefined,
              phoneNumberError: result?.message || undefined,
            })
          }
        })
        .catch((error) => {
          const errorMessage = getCodeAndMessageFromApiError(error).message

          const nextState: IUsePhoneNumberState = {
            updatingPhoneNumber: undefined,
            updatePhoneNumberResult: undefined,
            phoneNumberError: errorMessage,
          }

          setPhoneNumberState(nextState)

          console.error("Phone status error", error)
        })
    }
  }, [
    checkPhoneStatusOnMount,
    currentPhoneNumber,
    getPhoneNumberStatus,
    translate,
  ])

  return {
    currentPhoneNumber,
    updatePhoneNumber,
    resetPhoneNumberState,
    phoneNumberError: phoneNumberState.phoneNumberError,
    updatingStatus: {
      updatingPhoneNumber: phoneNumberState.updatingPhoneNumber,
      updatePhoneNumberResult: phoneNumberState.updatePhoneNumberResult,
    },
  }
}

export default usePhoneNumber
