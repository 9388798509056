import React from "react"
import clsx from "clsx"
import { TypographyProps } from "@mui/material/Typography"

import { Typography } from "../../_common"
import classes from "./TextElement.module.scss"

interface ITextElementProps extends Omit<TypographyProps, "variant"> {
  variant?: "title" | "subtitle1" | "subtitle2" | "body"
  warning?: boolean
}

const TextElement: React.FC<ITextElementProps> = ({
  variant = "body",
  children,
  className = undefined,
  warning = false,
  ...rest
}) => {
  let props: TypographyProps = {}

  switch (variant) {
    case "title":
      props = {
        className: classes.title,
        variant: "h5",
      }
      break
    case "subtitle1":
      props = {
        className: classes.subtitle1,
        variant: "h5",
      }
      break
    case "subtitle2":
      props = {
        className: classes.subtitle2,
      }
      break
    default:
      props = {
        className: classes.body,
      }
  }

  const { className: propsClassName } = props
  props.className = clsx(propsClassName, className, {
    [classes.warning]: !!warning,
  })

  return (
    <Typography {...rest} {...props}>
      {children}
    </Typography>
  )
}

export default TextElement
