import React, { ComponentProps } from "react"
import FiltersModule from "../../Experts/FiltersModule"
import { Typography } from "../../_common"
import { ExpertCard } from "../components/ExpertCard"
import { IUseWizardTracking } from "../types"
import { Screen } from "./Screen"
import classes from "./Expert.module.scss"

type IExpertScreen = Pick<IUseWizardTracking, "trackWizardStep"> & {
  expertScreenTitle: string
  expertScreenTestExpertsListing: Omit<
    ComponentProps<typeof FiltersModule>,
    "size"
  > & {
    size: number // Expert List backward compatibility, redefined via gql
  }
}

export const Expert: React.FC<IExpertScreen> = ({
  trackWizardStep,
  expertScreenTitle,
  expertScreenTestExpertsListing,
}) => {
  const {
    emptyState,
    size,
    includeListingNos,
    expertListHeadline,
    listingName,
    sys,
    display,
    withPagination,
  } = expertScreenTestExpertsListing

  return (
    <Screen rootClassName={classes.root}>
      <Typography component="h4" className={classes.title}>
        {expertScreenTitle}
      </Typography>

      <FiltersModule
        sys={sys}
        display={display}
        size={size}
        withPagination={withPagination}
        {...(includeListingNos && { includeListingNos })}
        {...(emptyState && { emptyState })}
        {...(expertListHeadline && { expertListHeadline })}
        {...(listingName && { listingName })}
        renderExpertItem={(expert) => (
          <ExpertCard expert={expert} trackWizardStep={trackWizardStep} />
        )}
      />
    </Screen>
  )
}
