import { useEffect } from "react"

import { TUseFormValues, TUseFormErrors, TAuthModuleForm } from "../types"
import { getFormFieldError } from "./utils"
import { useTracking } from "../../../hooks/tracking"
import { clientTrackingAuth } from "../../../hooks/authentication/trackingUtils"

const useTrackClientError = (
  values: TUseFormValues,
  errors: TUseFormErrors,
  formType: TAuthModuleForm
): void => {
  const { trackEvent: trackCustomEvent } = useTracking()

  useEffect(() => {
    if (errors.username) {
      const error = getFormFieldError(errors.username)
      if (error) {
        clientTrackingAuth(
          values.username?.value,
          error,
          formType,
          trackCustomEvent
        )
      }
    }
  }, [errors.username, formType, trackCustomEvent, values.username?.value])
}

export default useTrackClientError
