import React from "react"
import FullDOM from "../../FullDOM"

import { AdviqoButton, Logo, Typography } from "../../_common"
import { Screen } from "./Screen"
import classes from "./Intro.module.scss"

interface IIntroScreen {
  introScreenTitle: string
  introScreenActionTitle: string
  handleNext: () => void
  introScreenText: {
    html: string
    css: string
    sys: { id: string }
  }
}

export const Intro: React.FC<IIntroScreen> = ({
  introScreenText,
  introScreenTitle,
  introScreenActionTitle,
  handleNext,
}) => {
  return (
    <Screen rootClassName={classes.root}>
      <Logo withLink={false} wrapperClassName={classes.logo} />

      <Typography component="h4" className={classes.title}>
        {introScreenTitle}
      </Typography>

      <FullDOM {...introScreenText} />

      <AdviqoButton
        color="secondary"
        label={introScreenActionTitle || "Los geht's!"}
        onClick={handleNext}
        buttonClassName={classes.button}
        testId="start"
      />
    </Screen>
  )
}
