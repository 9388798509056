import React, { Suspense, useCallback, useState } from "react"

import { useUser } from "../../../hooks/user"
import usePhoneNumber from "../../PhoneNumber/usePhoneNumber"
import { AdviqoButton, Box, PaperBodyText } from "../../_common"
import TextElement from "../../ProductFlow/TextElement"
import { IPhoneInputState } from "../../PhoneNumber/types"
import classes from "./PhoneNumber.module.scss"

const LazyPhoneNumber = React.lazy(() => import("../../PhoneNumber"))

interface IPhoneNumberState extends IPhoneInputState {
  isPossible: boolean | undefined
  isDirty: boolean
}

enum Fields {
  PHONE = "phoneNumber",
  FORM = "phoneNumberForm",
}

export const PhoneNumber: React.FC = () => {
  const [phoneNumberState, setPhoneNumberState] = useState<IPhoneNumberState>({
    newPhoneNumber: undefined,
    isPossible: undefined,
    isDirty: false,
    countryCallingCode: undefined,
    countryCode: undefined,
    nationalNumber: undefined,
    phoneNumberError: undefined,
  })

  const {
    state: { initialLoadReady },
  } = useUser()

  const {
    currentPhoneNumber,
    updatePhoneNumber,
    phoneNumberError,
    resetPhoneNumberState,
    updatingStatus: { updatingPhoneNumber },
  } = usePhoneNumber(true)

  const handleChange: React.ComponentProps<
    typeof LazyPhoneNumber
  >["onChange"] = (options) => {
    resetPhoneNumberState()
    setPhoneNumberState((prevState) => ({
      ...prevState,
      ...options,
      isDirty: true,
    }))
  }

  const loading = !!updatingPhoneNumber || !initialLoadReady

  let ctaDisabled = loading || phoneNumberError !== undefined

  if (phoneNumberState.isDirty) {
    ctaDisabled = !phoneNumberState.isPossible
  }

  const handleSubmit = useCallback(
    async (e: React.FormEvent | MouseEvent) => {
      e.preventDefault()

      if (!phoneNumberState.newPhoneNumber) return

      setPhoneNumberState((prev) => ({ ...prev, isDirty: false }))

      updatePhoneNumber(phoneNumberState)
    },
    [phoneNumberState, updatePhoneNumber]
  )

  return (
    <Box className={classes.callSettings} data-test-id="form-with-text">
      <TextElement variant="title">
        Bitte gib deine Telefonnummer ein
      </TextElement>
      <PaperBodyText>
        Deine Telefonnummer bleibt anonym und ist für den Berater nie sichtbar
      </PaperBodyText>
      <form name={Fields.FORM} id={Fields.FORM} onSubmit={handleSubmit}>
        <Box className={classes.settingsWrapper}>
          <Suspense fallback={null}>
            <LazyPhoneNumber
              editMode="edit"
              enabledActions="none"
              disabled={loading}
              fieldName={Fields.PHONE}
              externalError={phoneNumberError}
              withExternalSuccess={false}
              defaultPhoneNumber={currentPhoneNumber || ""}
              onChange={handleChange}
              autoFocus={false}
            />
          </Suspense>
        </Box>
        <AdviqoButton
          fullWidth
          color="secondary"
          type="submit"
          label="Weiter"
          labelCentered
          onClick={handleSubmit}
          buttonClassName={classes.submit}
          disabled={ctaDisabled}
        />
      </form>
    </Box>
  )
}
