import React from "react"

import AuthModule from "../../ui-lib/Auth"
import { FingerprintProvider } from "../../hooks/fingerprints"
import { IAuthModule } from "../../graphql/contentful-schema"

const AuthModuleComponent: React.FC<{
  data: IAuthModule
}> = ({ data }) => {
  if (!data) {
    return null
  }

  const props = {
    loginTitle: data.loginTitle,
    registrationTitle: data.registrationTitle,
    authForm: data.authForm,
    successLoginRedirectPage: data.successLoginRedirectPage || "",
    successRegisterRedirectPage: data.successRegisterRedirectPage || "",
    loginFormTitle: data.loginFormTitle,
    loginFormPlaceholder: data.loginFormPlaceholder,
    standalone: data.standalone,
    registrationFooterText: data.registrationFooterText || "",
  }

  return (
    <FingerprintProvider>
      <AuthModule {...props} />
    </FingerprintProvider>
  )
}

export default AuthModuleComponent
